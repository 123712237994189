import Button from "$components/buttons/button.react";
import GdprConsentModal from "$components/gdpr/gdpr-consent-modal";
import RenderIf from "$components/render-if/render-if";
import { useCaseInsensitiveTranslation } from "$lib/hooks/case-insensitive-translation";
import { useQuery } from "$lib/hooks/fetch-utillities";
import { revalidateAllActiveQueries } from "$pages/common/GraphQLFetcher";
import DashboardEdit from "$pages/dashboard/dashboard-edit/dashboard-edit";
import PinnedSites from "$pages/dashboard/pinnedsites/pinned-sites";
import QuickSearch from "$pages/dashboard/quicksearch/quick-search";
import Shortcuts from "$pages/dashboard/shortcuts/shortcuts";
import Widgets from "$pages/dashboard/widgets/widgets";
import { DashboardQueryDocument } from "$typings/graphql-codegen";
import React, { FC, HTMLAttributes, useMemo, useState } from "react";
import { getUserFeatures } from "../../config/sessionService";
import { DashboardEditContext, IDashboardEditContext } from "./dashboard-edit/context/dashboard-edit-context";
import { useDashboardGroups } from "./dashboard-edit/hooks/useDashboardGroups";

const Dashboard: FC<HTMLAttributes<HTMLElement>> = () => {
  const [t] = useCaseInsensitiveTranslation()
  const [editDashboardOpen, setEditDashboardOpen] = useState(false)
  const dashboardAccess = getUserFeatures().dashboard.read
  const groups = useDashboardGroups()

  // Build context
  const dashboardEditContext: IDashboardEditContext = {
    title: t('UI_Dashboard_Edit_Dashboard'),
    open: {
      isOpen: editDashboardOpen,
      setOpen: setEditDashboardOpen
    },
    groups,
  }

  const editDashboardButtonText = useMemo<string>(
    () => {
      const str = editDashboardOpen
        ? 'UI_Dashboard_Header_Close_Edit_Dashboard'
        : 'UI_Dashboard_Edit_Dashboard'
      return t(str)
    },
    [editDashboardOpen]
  )

  const {data, loading} = useQuery(DashboardQueryDocument)

  function onClickEditDashboard() {
    setEditDashboardOpen(!editDashboardOpen)
  }

  function onConsent(): void {
    revalidateAllActiveQueries()
  }

  const displayRequireConfirmation: boolean = useMemo(
    () => !loading && !!(data?.currentUser.userSettings?.needConsent),
    [data]
  )
  
  const DashboardHeader = () => {
    return (
      <div className="flex justify-between items-center gap-2">
        <h1 className="m-0 font-normal">{t('UI_Dashboard_header')}</h1>
        <Button
          variant="secondary"
          onClick={onClickEditDashboard}>
          {editDashboardButtonText}
        </Button>
      </div>
    )
  }
  
  const DashboardStickyEditMenu = () => {
    return (
      <div className="sticky top-0 right-0 z-10">
        <DashboardEditContext.Provider value={dashboardEditContext}>
          <RenderIf if={editDashboardOpen}>
            <div className="absolute top-4 right-0" style={{width: '350px'}}>
              <DashboardEdit/>
            </div>
          </RenderIf>
        </DashboardEditContext.Provider>
      </div>
    )
  }

  return (
    <div className="flex justify-start flex-col p-4">
      <RenderIf if={dashboardAccess}>
        <DashboardHeader />
        <DashboardStickyEditMenu />
        <div className="dashboard-content">
          <Shortcuts />
          <QuickSearch />
          <Widgets />
          <PinnedSites />
        </div>
      </RenderIf>
      <RenderIf if={displayRequireConfirmation}>
        <GdprConsentModal onConsent={onConsent} />
      </RenderIf>
    </div>
  )
}

export default Dashboard
