import React, {FC, HTMLAttributes, useMemo} from 'react'
import './deliveries-year-to-date-widget.css'
import {useCaseInsensitiveTranslation} from "$lib/hooks/case-insensitive-translation";
import {CustomIcon} from "$components/icons/icon/CustomIcon";
import Icon from "$components/icons/icon/icon.react";
import DeliveriesWrapper
  from "$pages/dashboard/widgets/modules/deliveries-year-to-date-widget/modules/deliveries-wrapper/deliveries-wrapper";
import {useQuery} from "$lib/hooks/fetch-utillities";
import { DeliveriesWidgetQueryDocument } from '$typings/graphql-codegen';
import {months, shortMonths} from "$lib/dateHelpers";
import {removeNoneAndEmptyFromArray} from "$lib/arrayHelpers";
import WidgetScroller from "$pages/dashboard/widgets/modules/scroller/widget-scroller";
import RenderIf from "$components/render-if/render-if";
import {DeliveriesBuidData} from "$pages/dashboard/widgets/modules/deliveries-year-to-date-widget/deliveries-data";
import {usePersistedBooleanState} from "$lib/hooks/usePersistedState";
import TwCard from "$components/cards/tw-card/tw-card";
import TwCardHeader from "$components/cards/tw-card/tw-card-header";
import TwCardBody from "$components/cards/tw-card/tw-card-body";
import classNames from "classnames";

const DeliveriesYearToDateWidget: FC<HTMLAttributes<HTMLElement>> = () => {
  const CacheKey = 'DeliveriesYTDWidgetShowTable'
  const [t] = useCaseInsensitiveTranslation()
  const [showTable, setShowTable] = usePersistedBooleanState(CacheKey, false)
  
  const dynamicIcon = useMemo<CustomIcon>(
    (): CustomIcon => showTable ? CustomIcon.FILE_TEXT : CustomIcon.BAR_CHART_2,
    [showTable]
  )
  
  const {
    data,
    loading,
  } = useQuery(DeliveriesWidgetQueryDocument)
  
  function toggleShowTable() {
    setShowTable(!showTable)
  }
  
  function getDataByBuid(buid: string): DeliveriesBuidData[] {
    if (!buid) {
      buid = 'All'
    }
    
    return Array(13).fill(null).map((_, i) => {
      let sumDelivered = 0
      const date = new Date();

      //Set day to first of month and go back one year
      date.setDate(1);
      date.setMonth(date.getMonth() - (12-i));
      
      const month: string = (date.getMonth()+1).toString();
      const year: string = date.getFullYear().toString();
      const key = month + "." + year;
      
      if (buid === 'All') {
        const whereKeyMatch = (s: {key: string}): boolean => s.key === key
        const allBuilds = data?.deliveryNoteStatistics?.filter(whereKeyMatch)
        if (allBuilds?.length) {
          const amountsDelivered = allBuilds.map(v => v.amountDelivered)
          sumDelivered = amountsDelivered.reduce((prev, next) => prev + next)
        }
      } else {
        const foundResult = data?.deliveryNoteStatistics.find((s) => (
          s.key === key && s.buid === buid
        ))
        if (foundResult && foundResult.amountDelivered) {
          sumDelivered = foundResult.amountDelivered
        }
      }
      
      return {
        litersDelivered: sumDelivered,
        litersDeliveredFormatted: sumDelivered.toLocaleString(),
        monthShortText: t(shortMonths[month]),
        monthText: t(months[month]) + ' ' + year
      }
    })
  }
  
  const buids = useMemo<string[]>(
    () => data?.deliveryNoteStatistics.map(
      v => v.buid ?? ''
    ) ?? [],
    [data]
  )
  
  function isUnique<T>(value: T, index: number, self: T[]): boolean {
    return self?.indexOf(value) === index
  }
  
  const distinctBuids = useMemo<string[]>(
    () => {
      if (!data) return []
      const buidArray = removeNoneAndEmptyFromArray(buids as any[])
      const uniqueBuidArray = buidArray.filter(isUnique)
      uniqueBuidArray.sort()
      
      // All "All" if missing
      const missingAll = uniqueBuidArray?.indexOf('All') < 0
      if (missingAll) {
        uniqueBuidArray.unshift('All')
      }
      
      return uniqueBuidArray
    },
    [data]
  )
  
  const DynamicIcon = () => {
    return (
      <Icon
        name={dynamicIcon}
        onClick={toggleShowTable} />
    )
  }
  
  return (
    <TwCard className="deliveries-year-to-date-widget widget">
      <TwCardHeader className="justify-between">
        <div>{t('UI_Widget_Header_Deliveries')}</div>
        <DynamicIcon />
      </TwCardHeader>
      <TwCardBody className="flex-col gap-4 justify-end rounded overflow-hidden" style={{padding: '0'}}>
        <div className={classNames(!showTable && 'p-4')}>
          <RenderIf if={data}>
            <WidgetScroller
              rememberSlide={true}
              name="deliveries-ydt-widget">
              {distinctBuids.map((buid: string, i: number) => (
                <DeliveriesWrapper
                  key={buid + i}
                  title={buid}
                  showTable={showTable}
                  loading={loading}
                  data={getDataByBuid(buid)} />
              ))}
            </WidgetScroller>
          </RenderIf>
        </div>
      </TwCardBody>
    </TwCard>
  )
}

export default DeliveriesYearToDateWidget
