import React, {FC, HTMLAttributes} from "react";
import {useDashboardEditContext} from "../context/dashboard-edit-context";
import CardContent from "$components/cards/card-content/card-content.react";
import classNames from "classnames";
import {IDashboardGroup} from "../IDashboardGroup";
import DashboardEditGroup from "./dashboard-edit-group";

const DashboardEditContent: FC<HTMLAttributes<HTMLElement>> = (props) => {
  const ctx = useDashboardEditContext()
  const className = classNames('content p-4', props.className)

  return (
    <CardContent className={className}>
      {ctx.groups.map((group: IDashboardGroup) => (
        <DashboardEditGroup key={group.name} group={group}/>
      ))}
    </CardContent>
  )
}

export default DashboardEditContent
