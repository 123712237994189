import React, {FC, HTMLAttributes} from "react";
import {useDashboardEditContext} from "../context/dashboard-edit-context";
import classNames from "classnames";

const DashboardEditBackdrop: FC<HTMLAttributes<HTMLElement>> = (props) => {
  const ctx = useDashboardEditContext()
  function close(): void {
    ctx.open.setOpen(false)
  }
  const className = classNames("edit-dashboard-backdrop showOnlyOnMobile", props.className)
  return <div className={className} onClick={close}/>
}

export default DashboardEditBackdrop
