import Icon from "$components/icons/icon/icon.react";
import TextField from "$components/textbox/text-field.react";
import { useCaseInsensitiveTranslation } from "$lib/hooks/case-insensitive-translation";
import React, { FC } from 'react';
import StatelessQuickSearchWidget from "../common/stateless-quick-search-widget";
import useSoldToQuickSearchQuery from './hooks/useSoldToQuickSearchQuery';

export interface SoldToQuickSearchProps {}

const SoldToQuickSearch: FC<SoldToQuickSearchProps> = () => {
  const [t] = useCaseInsensitiveTranslation()
  const Header = t('UI_Dashboard_Quicksearch_SoldTo')

  const {
    mutatedData,
    loading,
    showResult,
    showNoMatch,
    siteListUrl,
    searchValue,
    setSearchValue,
  } = useSoldToQuickSearchQuery()
  
  const SearchIcon: JSX.Element = <Icon name={'fa-search'}/>

  const SearchField: JSX.Element = (
    <TextField
      value={searchValue}
      icon={SearchIcon}
      placeholder={Header}
      removeWhitespace={true}
      onChange={(e) => setSearchValue(e.target.value)} />
  )

  const SearchResultComponent = (
    <a 
      className="no-underline"
      href={siteListUrl}>
      <p className="truncate">
        {mutatedData?.customer ?? ''}
      </p>
    </a>
  )
  
  return (
    <StatelessQuickSearchWidget
      header={Header}
      searchComponent={SearchField}
      isLoading={loading}
      showResult={showResult}
      showNoMatch={showNoMatch}
      searchResultComponent={SearchResultComponent}
      dataCount={mutatedData?.totalCount ?? 0}
      url={siteListUrl} />
  )
}

export default SoldToQuickSearch
