import React, {FC, HTMLAttributes, useMemo} from 'react'
import './site-status-bar.css'
import {ISiteCardSite} from "$interfaces/application";
import {ISiteListSite} from "$interfaces/entity";
import {
  SignalEnum,
  SiteStatusBarFragment,
  SiteStatusBarFragment_alarms,
  SiteStatusBarFragment_channels,
  SiteStatusBarFragment_controllers
} from "$typings/graphql";
import {SiteSignal, SiteStatus} from "../../../models";
import classNames from "classnames";

type IconDefinition = {
  className: string,
  iconName: string
}

export interface SiteStatusBarProps extends HTMLAttributes<HTMLElement> {
  site?: ISiteCardSite | ISiteListSite
  siteGraphQl?: SiteStatusBarFragment
  onClick: () => any,
  noBackground: boolean
}

const SiteStatusBar: FC<SiteStatusBarProps> = (props) => {
  function allChannelsAreParked(channels: SiteStatusBarFragment_channels[]): boolean {
    return channels.every(channel => channel.isParked)
  }
  
  function someAlarmsAreActive(alarms: SiteStatusBarFragment_alarms[]): boolean {
    return alarms.some(alarm => alarm.active)
  }
  
  function allControllersAreVirtual(controllers: SiteStatusBarFragment_controllers[]): boolean {
    return controllers.every(controller => controller.isVirual)
  }
  
  // Get site status from site (GQL object structure)
  function getSiteHealthFromGraphQL(site: SiteStatusBarFragment): SiteStatus {
    // Define base signal states
    const isStale = site.signal == SignalEnum.Stale
    const isOffline = site.signal == SignalEnum.Offline
    const allSiteChannelsAreParked = allChannelsAreParked(site.channels ?? [])
    const someSiteAlarmsAreActive = someAlarmsAreActive(site.alarms ?? [])
    const allSiteControllersAreVirtual = allControllersAreVirtual(site.controllers ?? [])
    
    // Defined composite states
    const isStaleParked = isStale && allSiteChannelsAreParked && !someSiteAlarmsAreActive
    const isOfflineParked = isOffline && allSiteChannelsAreParked && !someSiteAlarmsAreActive
    
    if (isStaleParked) return SiteStatus.StaleParked
    if (isOfflineParked) return SiteStatus.OfflineParked
    if (allSiteChannelsAreParked && someSiteAlarmsAreActive) return SiteStatus.AlarmParked
    if (allSiteControllersAreVirtual) return SiteStatus.Virtual
    if (someSiteAlarmsAreActive) return SiteStatus.Alarm
    if (isStale) return SiteStatus.Stale
    if (isOffline) return SiteStatus.Offline
    if (allSiteChannelsAreParked) return SiteStatus.Parked
    
    return SiteStatus.Normal
  }
  
  // Get site status from site (pre-GQL era object structure) i.e. deprecated
  function getSiteHealth(site: ISiteCardSite | ISiteListSite): SiteStatus {
    const isStale = site.signal == SiteSignal.Stale
    const isOffline = site.signal == SiteSignal.Offline
    
    const isStaleParked = isStale && site.isParked && !site.hasAlarm
    const isOfflineParked = isOffline && site.isParked && !site.hasAlarm
    const isAlarmParked = site.hasAlarm && site.isParked
    
    if (isStaleParked) return SiteStatus.StaleParked
    if (isOfflineParked) return SiteStatus.OfflineParked
    if (isAlarmParked) return SiteStatus.AlarmParked
    
    if (site.hasAlarm) return SiteStatus.Alarm
    if (site.isVirtual) return SiteStatus.Virtual
    if (site.signal == SiteSignal.Stale) return SiteStatus.Stale
    if (site.signal == SiteSignal.Offline) return SiteStatus.Offline
    if (site.isParked) return SiteStatus.Parked
    
    return SiteStatus.Normal
  }
  
  // Assemble an IconDefinition object structure
  function buildIconDefinition(className: string, iconName: string): IconDefinition {
    return { className, iconName }
  }
  
  // Define record of available icons and their respective class names
  const IconRecord = {
    Alarm: buildIconDefinition('alarm', 'alarm'),
    OfflineCross: buildIconDefinition('offline', 'cross'),
    Parking: buildIconDefinition('parked', 'parking'),
    OfflineParking: buildIconDefinition('offline', 'parking'),
    StaleParking: buildIconDefinition('stale', 'parking'),
    StaleUncertain: buildIconDefinition('stale', 'uncertain'),
    AlarmParking: buildIconDefinition('alarm', 'parking'),
    Tick: buildIconDefinition('', 'tick')
  }
  
  // Get icon definition from site health
  function getIconDefinition(siteHealth: SiteStatus | null): IconDefinition {
    switch (siteHealth) {
      case SiteStatus.Alarm: return IconRecord.Alarm
      case SiteStatus.Offline: return IconRecord.OfflineCross
      case SiteStatus.Parked: return IconRecord.Parking
      case SiteStatus.OfflineParked: return IconRecord.OfflineParking
      case SiteStatus.StaleParked: return IconRecord.StaleParking
      case SiteStatus.Virtual:
      case SiteStatus.Stale:
        return IconRecord.StaleUncertain
      case SiteStatus.AlarmParked: return IconRecord.AlarmParking
      case SiteStatus.Normal:
      default:
        return IconRecord.Tick
    }
  }
  
  // Reactive site health
  const siteHealth: SiteStatus | undefined = useMemo(
    () => {
      if (props.siteGraphQl) return getSiteHealthFromGraphQL(props.siteGraphQl)
      if (props.site) return getSiteHealth(props.site)
      return undefined
    },
    [props.siteGraphQl, props.site]
  )
  
  // Reactive icon definition
  const iconDefinition = useMemo(
    () => getIconDefinition(siteHealth ?? SiteStatus.Offline),
    [siteHealth]
  )
  
  // Reactive class name
  const className = useMemo(
    () => classNames('site-status', iconDefinition.className, props.className),
    [iconDefinition, props.noBackground, props.className]
  )

  // Icon src URI
  const iconSrc = useMemo(
    () => `icons/${iconDefinition.iconName}.svg`,
    [iconDefinition]
  )

  return (
    <div className={className}>
      <img
        src={iconSrc}
        alt={iconDefinition.iconName}/>
    </div>
  )
}

export default SiteStatusBar
