import Card from "$components/cards/card/card.react";
import classNames from 'classnames';
import React, {FC, HTMLAttributes} from 'react';
import DashboardEditBackdrop from './components/dashboard-edit-backdrop';
import DashboardEditContent from './components/dashboard-edit-content';
import DashboardEditFooter from './components/dashboard-edit-footer';
import DashboardEditHeader from './components/dashboard-edit-header';
import './dashboard-edit.css';

const DashboardEdit: FC<HTMLAttributes<HTMLElement>> = (props) => {
  return (
    <div className={classNames(props)}>
      <DashboardEditBackdrop />
      <Card className="edit-dashboard-card">
        <DashboardEditHeader />
        <DashboardEditContent />
        <DashboardEditFooter />
      </Card>
    </div>
  )
}

export default DashboardEdit
