import React, {FC, HTMLAttributes, useMemo, useState} from 'react'
import {useCaseInsensitiveTranslation} from "$lib/hooks/case-insensitive-translation";
import {useQuery} from "$lib/hooks/fetch-utillities";
import {SitesRecentlyViewedWidgetsQueryDocument} from "$typings/graphql-codegen";
import PlaceholderText from "$components/placeholders/placeholder-text/placeholder-text.react";
import RenderIf from "$components/render-if/render-if";
import Button from "$components/buttons/button.react";
import {usePersistedParsedState} from "$lib/hooks/usePersistedState";
import TwCard from "$components/cards/tw-card/tw-card";
import TwCardHeader from "$components/cards/tw-card/tw-card-header";
import TwCardBody from "$components/cards/tw-card/tw-card-body";
import classNames from "classnames";

type MutatedData = {
  alias: string,
  siteId: number
}

const SitesRecentlyViewedWidget: FC<HTMLAttributes<HTMLElement>> = ({className}) => {
  const CacheKey = 'recently-viewed-sites'
  const DefaultSiteIdArray = [-1]
  const NumberOfSitesToShow = 5
  
  const [t] = useCaseInsensitiveTranslation()
  const [error, setError] = useState<string | null>(null)
  const [lastViewedSiteIds] = usePersistedParsedState(CacheKey, DefaultSiteIdArray)
  
  const Variables = (() => {
    const slicedIds = lastViewedSiteIds.slice(0, NumberOfSitesToShow)
    const toValueObj = (v: any) => ({ value: '' + v })
    return {
      filters: JSON.stringify(
        [
          {
            field: 'siteIdRef',
            type: 'ref',
            exclude: false,
            filters: slicedIds.map(toValueObj)
          }
        ]
      ),
      numberToShow: NumberOfSitesToShow
    }
  })()
  
  const {
    data,
    loading,
    revalidate
  } = useQuery(
    SitesRecentlyViewedWidgetsQueryDocument,
    Variables,
    undefined,
    undefined,
    (error: string) => {
      setError(error)
    }
  )
  
  const mutatedData = useMemo(
    () => {
      if (!data) {
        return undefined
      }
      
      return lastViewedSiteIds?.map((id: number) => (
        data?.elasticSearchPages?.sitelist?.data?.edges?.find((edge) => (
          edge?.siteId === id.toString()
        ))
      )).filter((id) => !!id)
    },
    [data]
  )
  
  function accessArrayItem<T>(array: T[], index: number) {
    if (!array) return null
    return array[index] ? array[index] : null
  }
  
  function getSiteDetailsURL(array: MutatedData[], index: number) {
    const siteId = accessArrayItem<MutatedData>(array, index)?.siteId
    return `/sitedetails/${siteId ?? ''}`
  }

  function reFetch(): void {
    setError(null)
    revalidate && revalidate()
  }
  
  return (
    <TwCard className={classNames('sites-recently-viewed-widget', className)}>
      <TwCardHeader>{t('UI_Widget_Header_Viwed_Sites')}</TwCardHeader>
      <TwCardBody className="flex-col gap-4">
        <RenderIf if={loading}>
          <div className="grow flex flex-col gap-4">
            {([...Array(NumberOfSitesToShow).fill(null)]).map((_, i) => (
              <div key={i}>
                <div className="sitename">
                  <PlaceholderText/>
                </div>
              </div>
            ))}
          </div>
        </RenderIf>
        <RenderIf if={data && mutatedData?.length}>
          <div className="grow flex flex-col gap-4">
            {([...Array(NumberOfSitesToShow).fill(null)]).map((_, i) => (
              <div
                key={i}
                className="row">
                <div className="sitename trunc-text">
                  <a
                    href={getSiteDetailsURL(mutatedData as [], i)}
                    className="no-text-decoration">
                    {accessArrayItem<MutatedData>(mutatedData as [], i)?.alias ?? ''}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </RenderIf>
        <RenderIf if={data && !mutatedData?.length}>
          <div className="empty">
            <p>{t('ui_dashboard_item_no_recently_viewed_widgets')}</p>
          </div>
        </RenderIf>
        <RenderIf if={!!error && !loading}>
          <div className="flex column jccenter aicenter flex_1">
            <div>
              {t('UI_Dashboard_Item_ErrorLoadingData')}
            </div>
            <Button onClick={reFetch}>
              {t('UI_Common_Try_Again')}
            </Button>
          </div>
        </RenderIf>
      </TwCardBody>
    </TwCard>
  )
}

export default SitesRecentlyViewedWidget
