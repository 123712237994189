import Checkbox from "$components/checkbox/checkbox.react";
import { useCaseInsensitiveTranslation } from "$lib/hooks/case-insensitive-translation";
import usePersistedItemVisibility from "$pages/dashboard/hooks/usePersistedItemVisibility";
import React, { FC, HTMLAttributes, useMemo } from "react";
import { DashboardItemNameEnum } from "../DashboardItemNameEnum";
import { IDashboardGroup } from "../IDashboardGroup";

export interface DashboardEditGroupProps extends HTMLAttributes<HTMLElement> {
  group: IDashboardGroup
}

const DashboardEditGroup: FC<DashboardEditGroupProps> = (props) => {
  const [t] = useCaseInsensitiveTranslation()
  const { show, hide, items } = usePersistedItemVisibility()

  function itemSelected(item: DashboardItemNameEnum): boolean {
    return items.includes(item)
  }

  const allChecked = useMemo<boolean>(() => {
    const group = props.group
    let allItemsSelected: boolean = false
    let currentGroupSelected: boolean = false
    if (group.items) {
      allItemsSelected = group.items?.every(itemSelected) ?? false
    } else {
      currentGroupSelected = items.includes(group.name)
    }
    return allItemsSelected || currentGroupSelected
  }, [props.group.name, items])

  function flattenGroup(group: IDashboardGroup): DashboardItemNameEnum[] {
    const currentGroupName: DashboardItemNameEnum = group.name
    const currentGroupItems: DashboardItemNameEnum[] = group?.items ?? []
    return [currentGroupName, ...currentGroupItems]
  }

  function removeGroupFromSelection(group: IDashboardGroup): void {
    const itemsToRemove: DashboardItemNameEnum[] = flattenGroup(group)
    hide(...itemsToRemove)
  }

  function addGroupToSelection(group: IDashboardGroup): void {
    const itemsToAdd: DashboardItemNameEnum[] = flattenGroup(group)
    show(...itemsToAdd)
  }

  function toggleGroup(group: IDashboardGroup): void {
    const currentGroupIsChecked = itemSelected(group.name)
    if (currentGroupIsChecked) {
      removeGroupFromSelection(group)
    } else {
      addGroupToSelection(group)
    }
  }

  function toggleItem(item: DashboardItemNameEnum): void {
    const itemIsChecked = itemSelected(item)
    if (itemIsChecked) {
      hide(item)
    } else {
      show(item)
    }
  }

  const GroupCheckbox = () => {
    const checked = allChecked.valueOf()
    const onChange = () => toggleGroup(props.group)
    const text = t(props.group.name)
    return <Checkbox checked={checked} onChange={onChange} text={text}/>
  }

  const ItemCheckbox = (props: { item: DashboardItemNameEnum }) => (
    <Checkbox
      checked={itemSelected(props.item)}
      onChange={() => toggleItem(props.item)}
      text={t(props.item)}/>
  )

  const ItemCheckboxes = () => {
    const items = props.group?.items ?? []
    const checkboxes = items.map((item) => (
      <ItemCheckbox key={item} item={item}/>
    ))
    return <>{checkboxes}</>
  }

  return (
      <div>
        <div className="mar_bm">
          <GroupCheckbox/>
        </div>
        <div className="sub-category pl-2">
          <ItemCheckboxes/>
        </div>
      </div>
  )
}

export default DashboardEditGroup
