import React, {FC, HTMLAttributes, ReactNode} from "react";
import {useCaseInsensitiveTranslation} from "$lib/hooks/case-insensitive-translation";
import RequireConfirmation from "$components/require-confirmation/require-confirmation.react";
import {useAuth} from "$lib/hooks/useAuth";
import {mutate} from "$lib/hooks/fetch-utillities";
import {ConsentDocument, GenericMutationResponse} from "$typings/graphql-codegen";

export interface GdprConsentModalProps extends HTMLAttributes<HTMLElement> {
  onConsent?: (response?: GenericMutationResponse) => any
  onDecline?: () => any
}

const GdprConsentModal: FC<GdprConsentModalProps> = (props) => {
  const [t] = useCaseInsensitiveTranslation()
  const [logout] = useAuth()
  const Text = {
    Header: t('UI_GDPR_Consent_Modal_Header'),
    ConsentButton: t('UI_Common_IAgree'),
    DeclineButton: t('UI_Common_IDecline'),
    ParagraphOne: t('UI_GDPR_Consent_Modal_Text_First'),
    ParagraphTwo: t('UI_GDPR_Consent_Modal_Text_Second'),
    ParagraphThreeAsHtml: t('UI_GDPR_Consent_Modal_Text_Third'),
  }
  
  async function onConsent(): Promise<void> {
    const data = await mutate(ConsentDocument, undefined)
    if (props.onConsent) {
      const response: GenericMutationResponse | undefined = data?.consent ?? undefined
      props.onConsent(response)
    }
  }
  
  function onDecline(): void {
    if (props.onDecline) {
      props.onDecline()
    }
    logout()
    window.location.href = '/Account/Login'
  }
  
  const ConfirmBox: FC<ReactNode> = (props) => (
    <RequireConfirmation
      headerText={Text.Header}
      submitText={Text.ConsentButton}
      cancelText={Text.DeclineButton}
      confirmType="primary"
      onConfirm={onConsent}
      onCancel={onDecline}>
      { props.children }
    </RequireConfirmation>
  )
  
  const GdprContent: FC<ReactNode> = () => (
    <div
      className="flex flex-col"
      style={{rowGap: 20}}>
      <p>{Text.ParagraphOne}</p>
      <p>{Text.ParagraphTwo}</p>
      <p dangerouslySetInnerHTML={{
        __html: Text.ParagraphThreeAsHtml
      }}></p>
    </div>
  )

  return (
    <ConfirmBox>
      <GdprContent />
    </ConfirmBox>
  )
}

export default GdprConsentModal
