import Icon from "$components/icons/icon/icon.react";
import RenderIf from "$components/render-if/render-if";
import TextField from "$components/textbox/text-field.react";
import { useCaseInsensitiveTranslation } from "$lib/hooks/case-insensitive-translation";
import DashboardSiteChannelBar from "$pages/dashboard/common/dashboard-site-channel-bar";
import React, { FC } from 'react';
import StatelessQuickSearchWidget from '../common/stateless-quick-search-widget';
import useCustomerRefQuickSearchQuery from './hooks/useCustomerRefQuickSearchQuery';

export interface CustomerRefQuickSearch {}

const CustomerRefQuickSearch: FC<CustomerRefQuickSearch> = () => {
  const [t] = useCaseInsensitiveTranslation()
  const Heading: string = t('UI_Dashboard_Quicksearch_CustomerRef')
  
  const {
    mutatedData,
    loading,
    showResult,
    showNoMatch,
    siteUrl,
    siteListUrl,
    searchValue,
    setSearchValue,
  } = useCustomerRefQuickSearchQuery()

  const SearchIcon: JSX.Element = <Icon name={'fa-search'}/>
  
  const SearchField = (
    <TextField
      value={searchValue}
      icon={SearchIcon}
      placeholder={Heading}
      onChange={(e) => setSearchValue(e.target.value)}>
    </TextField>
  )
  
  const SearchResult = (
    <a
      className="flex gap-2 no-underline"
      href={siteUrl}>
      <RenderIf if={mutatedData?.percentage}>
        <DashboardSiteChannelBar
          lastSample={mutatedData?.lastSample}
          percentage={mutatedData?.percentage}
          minimum={mutatedData?.minimum}
          maximum={mutatedData?.maximum}
          capacity={mutatedData?.capacity}
          lastSampleTime={mutatedData?.lastSampleTime}
          reachMinimum={mutatedData?.reachMinimum}>
        </DashboardSiteChannelBar>
      </RenderIf>
      <p className="truncate">{mutatedData?.alias}</p>
    </a>
  )

  return (
    <StatelessQuickSearchWidget
      header={Heading}
      searchComponent={SearchField}
      isLoading={loading}
      showResult={showResult}
      showNoMatch={showNoMatch}
      searchResultComponent={SearchResult}
      dataCount={mutatedData?.totalCount ?? 0}
      url={siteListUrl} />
  )
}

export default CustomerRefQuickSearch
