import Icon from "$components/icons/icon/icon.react";
import RenderIf from "$components/render-if/render-if";
import TextField from "$components/textbox/text-field.react";
import { useCaseInsensitiveTranslation } from "$lib/hooks/case-insensitive-translation";
import DashboardSiteChannelBar from "$pages/dashboard/common/dashboard-site-channel-bar";
import React, { FC } from 'react';
import StatelessQuickSearchWidget from '../common/stateless-quick-search-widget';
import useFreetextQuickSearchQuery from './hooks/useFreetextQuickSearchQuery';

export interface FreetextQuickSearchProps {}

const FreetextQuickSearch: FC<FreetextQuickSearchProps> = () => {
  const [t] = useCaseInsensitiveTranslation()
  const Header = t('UI_Dashboard_Quicksearch_FreeText')

  const {
    mutatedData,
    loading,
    showResult,
    showNoMatch,
    siteUrl,
    siteListUrl,
    searchValue,
    setSearchValue,
  } = useFreetextQuickSearchQuery()

  const SearchIcon: JSX.Element = <Icon name="fa-search" />
  
  const SearchBar = (
    <TextField
      value={searchValue}
      icon={SearchIcon}
      placeholder={Header}
      onChange={(e) => setSearchValue(e.target.value)} />
  )
  
  const PercentBar = (
    <DashboardSiteChannelBar
      lastSample={mutatedData?.lastSample}
      percentage={mutatedData?.percentage}
      minimum={mutatedData?.minimum}
      maximum={mutatedData?.maximum}
      capacity={mutatedData?.capacity}
      lastSampleTime={mutatedData?.lastSampleTime}
      reachMinimum={mutatedData?.reachMinimum} />
  )

  const SearchResultComponent = (
    <a
      className="no-underline flex gap-2"
      href={siteUrl}>
      <RenderIf if={mutatedData?.percentage}>
        { PercentBar }
      </RenderIf>
      <p className="truncate">{mutatedData?.alias}</p>
    </a>
  )
  
  return (
    <StatelessQuickSearchWidget
      header={Header}
      searchComponent={SearchBar}
      isLoading={loading}
      showResult={showResult}
      showNoMatch={showNoMatch}
      searchResultComponent={SearchResultComponent}
      dataCount={mutatedData?.totalCount ?? 0}
      url={siteListUrl} />
  )
}

export default FreetextQuickSearch
