import React, {FC, HTMLAttributes, useMemo, useRef} from 'react';
import './collapsable-section.css';
import ClickableIcon from '$components/icons/icon-clickable/clickable-icon.react';
import Icon from '$components/icons/icon/icon.react';
import RenderIf from '$components/render-if/render-if';
import { usePersistedBooleanState } from '$lib/hooks/usePersistedState';

export interface ICollapsableSection extends HTMLAttributes<HTMLElement> {
  name?: string;
  persistState?: boolean;
  header: string;
  action?: () => void;
  actionIcon?: string;
}

const CollapsableSection: FC<ICollapsableSection> = ({
  children,
  ...props
}) => {
  const DefaultName = 'collapsable-section'
  const DefaultOpenState = true;
  const ContentWrapperRef = useRef<HTMLDivElement>(null)
  const ContentRef = useRef<HTMLDivElement>(null)

  // Open state
  const [open, setOpen] = usePersistedBooleanState(
    (props?.persistState && props?.name) ? props.name : DefaultName,
    DefaultOpenState
  )

  // Computed attribute string
  const openAttribute = useMemo(
    () => open ? 'open' : 'closed',
    [open]
  )

  // Toggle state
  function toggleOpen(): void {
    setOpen(!open);
  }

  const Title = (
    <div className="csw-title" onClick={toggleOpen}>
      <ClickableIcon
        className="csw-indicator-icon"
        name="fa-chevron-down"
        onClick={toggleOpen}
      />
      <h2 className="csw-title-text m-0">
        {props.header}
      </h2>
    </div>
  )

  const Action = (
    <div className="csw-action">
      <RenderIf if={props.actionIcon}>
        <Icon
          className="csw-action-icon"
          name={props.actionIcon as string}
          onClick={props.action}
        />
      </RenderIf>
    </div>
  )

  const Header = (
    <div className="csw-header">
      { Title }
      { Action }
    </div>
  )

  const Content = (
    <div
      ref={ContentWrapperRef}
      className="csw-content-wrapper">
      <div
        ref={ContentRef}
        className="csw-content">
        {children}
      </div>
    </div>
  )

  return (
    <div className="csw" data-state={openAttribute}>
      { Header }
      { Content }
    </div>
  );
};

export default CollapsableSection;
