import { usePersistedParsedState } from "$lib/hooks/usePersistedState";
import { DashboardItemNameEnum } from "../dashboard-edit/DashboardItemNameEnum";

export default function usePersistedItemVisibility() {
  const CacheKey = 'dashboardSelectedItemsKey';
  const DefaultValue = Object.values(DashboardItemNameEnum)

  const [
    items,
    setItems
  ] = usePersistedParsedState<DashboardItemNameEnum[]>(
    CacheKey,
    DefaultValue
  )

  function show(...itemsToShow: DashboardItemNameEnum[]): void {
    const copy = Array.from(items)
    const merged = copy.concat(itemsToShow)
    const set = new Set(merged)
    const unique = Array.from(set)
    setItems(unique)
  }

  function hide(...itemsToHide: DashboardItemNameEnum[]): void {
    const copy = Array.from(items)
    const visibles = copy.filter((item) => !itemsToHide.includes(item))
    setItems(visibles)
  }

  return {
    items,
    show,
    hide
  }
}