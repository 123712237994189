import React, {FC, HTMLAttributes} from 'react'
import './deliveries-wrapper.css'
import {DeliveriesBuidData} from "$pages/dashboard/widgets/modules/deliveries-year-to-date-widget/deliveries-data";
import DeliveriesTable
  from "$pages/dashboard/widgets/modules/deliveries-year-to-date-widget/modules/deliveries-table/deliveries-table";
import DeliveriesChart
  from "$pages/dashboard/widgets/modules/deliveries-year-to-date-widget/modules/deliveries-chart/deliveries-chart";
import RenderIf from "$components/render-if/render-if";

export interface DeliveriesWrapperProps extends HTMLAttributes<HTMLElement> {
  title: string,
  data: DeliveriesBuidData[]
  loading: boolean
  showTable: boolean
}

const DeliveriesWrapper: FC<DeliveriesWrapperProps> = (props) => (
  <div
    title={props.title}
    className="deliveries-wrapper">
    <RenderIf if={!props.showTable}>
      <DeliveriesChart
        data={props.data}
        loading={props.loading}/>
    </RenderIf>
    <RenderIf if={props.showTable}>
      <DeliveriesTable
        data={props.data}
        loading={props.loading}/>
    </RenderIf>
  </div>
)

export default DeliveriesWrapper
