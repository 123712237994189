import React, {FC, HTMLAttributes} from 'react'
import './slide-wrapper.css'
import RenderIf from "$components/render-if/render-if";
import {NewSitesData} from "$pages/dashboard/widgets/modules/new-sites-widget/common/new-sites-data";
import NewSitesChart from "$pages/dashboard/widgets/modules/new-sites-widget/new-sites-chart/new-sites-chart";
import NewSitesTable from "$pages/dashboard/widgets/modules/new-sites-widget/new-sites-table/new-sites-table";

export interface SlideWrapperProps extends HTMLAttributes<HTMLElement> {
  data: NewSitesData,
  isLoading: boolean,
  showTable: boolean
}

const SlideWrapper: FC<SlideWrapperProps> = (props) => {
  return (
    <div
      title={props.data.buid}
      className="slide-wrapper">
      <RenderIf if={!props.showTable}>
        <NewSitesChart data={props.data} />
      </RenderIf>
      <RenderIf if={props.showTable}>
        <NewSitesTable
          data={props.data}
          isLoading={props.isLoading} />
      </RenderIf>
    </div>
  )
}

export default SlideWrapper
