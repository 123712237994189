
import React, { FC, HTMLAttributes, useMemo } from 'react'
import './idax-lifecycle-widget.css'
import { useCaseInsensitiveTranslation } from "$lib/hooks/case-insensitive-translation";
import Icon from "$components/icons/icon/icon.react";
import { useQuery } from "$lib/hooks/fetch-utillities";
import { IdaxLifecycleWidgetDataDocument } from "$typings/graphql-codegen";
import RenderIf from "$components/render-if/render-if";
import PlaceholderText from "$components/placeholders/placeholder-text/placeholder-text.react";
import { useIsMobile } from "$lib/hooks/isMobile";
import WidgetScroller from "$pages/dashboard/widgets/modules/scroller/widget-scroller";
import { usePersistedBooleanState, usePersistedState } from "$lib/hooks/usePersistedState";
import {
  MainColors,
  NumberOfTypesToShow,
  percentReducer,
  SelectedBuidCacheKey,
  PercentOrNumberCacheKey,
  WidgetCircleValue
} from "$pages/dashboard/widgets/modules/idax-lifecycle-widget/idax-lifecycle";
import { round } from "$lib/helpers";
import TwCard from "$components/cards/tw-card/tw-card";
import TwCardHeader from "$components/cards/tw-card/tw-card-header";
import TwCardBody from "$components/cards/tw-card/tw-card-body";
import TwCardFooter from "$components/cards/tw-card/tw-card-footer";
import classNames from "classnames";
import { CustomIcon } from '$components/icons/icon/CustomIcon';
import { getUserFeatures } from '../../../../../config/sessionService';


export interface IdaxLifecycleWidgetProps extends HTMLAttributes<HTMLElement> { }

const IdaxLifecycleWidget: FC<IdaxLifecycleWidgetProps> = (props) => {
  const [t] = useCaseInsensitiveTranslation()
  const [selectedBuid, setSelectedBuid] = usePersistedState(SelectedBuidCacheKey, '')
  const [showPercent, setShowPercent] = usePersistedBooleanState(PercentOrNumberCacheKey, true)
  const isMobile = useIsMobile()
  const features = getUserFeatures();

  const {
    data,
    loading
  } = useQuery(
    IdaxLifecycleWidgetDataDocument
  )

  const distinctBuids = useMemo(
    () => data?.idaxLifecycleWidget.map(v => v.buid) ?? [],
    [data]
  )

  function getStrokeOffset(index: number, array: any[]): number {
    const slicedCopy = array.slice(0, index)
    const percentageSum = slicedCopy.reduce(percentReducer, 0)
    return 100 - percentageSum + 25
  }

  function onSlideTitle(buid: string): void {
    selectedBuid
    setSelectedBuid(buid)
  }

  function toggleShowPercent(): void {
    setShowPercent(!showPercent)
    
  }

  function getSortedData(buid?: string) {
    const noData = !data
    if (noData) return []

    const lifecycleData = data?.idaxLifecycleWidget.filter((obj) => obj?.buid === buid)[0] ?? []

    const result: WidgetCircleValue[] = [];

    if (lifecycleData.shipped)
      result.push({
        color: MainColors[0],
        type: t('ui_widget_idax_lifecycle_type_shipped'),
        percent: (lifecycleData.shipped / lifecycleData.total) * 100,
        count: lifecycleData.shipped,
        buidId: lifecycleData.buidId ?? ''
      });

    if (lifecycleData.active)
      result.push({
        color: MainColors[1],
        type: t('ui_widget_idax_lifecycle_type_active'),
        percent: (lifecycleData.active / lifecycleData.total) * 100,
        count: lifecycleData.active
      });

    if (lifecycleData.activated)
      result.push({
        color: MainColors[2],
        type: t('ui_widget_idax_lifecycle_type_activated'),
        percent: (lifecycleData.activated / lifecycleData.total) * 100,
        count: lifecycleData.activated,
        buidId: lifecycleData.buidId ?? ''
      });

    if (lifecycleData.decommissioned)
      result.push({
        color: MainColors[3],
        type: t('ui_widget_idax_lifecycle_type_decommissioned'),
        percent: (lifecycleData.decommissioned / lifecycleData.total) * 100,
        count: lifecycleData.decommissioned,
        buidId: lifecycleData.buidId ?? ''
      });
    if (lifecycleData.idle)
      result.push({
        color: MainColors[4],
        type: t('ui_widget_idax_lifecycle_type_idle'),
        percent: (lifecycleData.idle / lifecycleData.total) * 100,
        count: lifecycleData.idle
      });    
    return result;

  }

  const createTypeLink = (data: WidgetCircleValue)  => {

    const idaX11ControllerTypeFilter = 'controllerType=ida-11&controllerType=ida11&controllerType=ida211&controllerType=ida-211&controllerType=idaxcloud&controllerType=idax-cloud&controllerType=idacloud'
    const idaX11DeviceTypeFilter = 'deviceType=ida-11&deviceType=ida11&deviceType=ida211&deviceType=ida-211&deviceType=idaxcloud&deviceType=idax-cloud&deviceType=idacloud';

    switch (data.type) {
      case "Shipped":
          const shippedSortFilter = 'sortProperty=shippeddate&sortDirection=desc';
          const shippedLink = data.buidId ? `/sim-management/sim-device-map?buid=${data.buidId}&${shippedSortFilter}&${idaX11DeviceTypeFilter}` : `/sim-management/sim-device-map?${shippedSortFilter}&${idaX11DeviceTypeFilter}`
          return <a style={{textDecoration: 'none'}} href={shippedLink}>{data.type}</a>;

      case "Activated":
         const activatedSortFilter = 'sortProperty=activated&sortDirection=desc';
         const activatedLink = data.buidId ? `/sim-management/sim-device-map?buid=${data.buidId}&${activatedSortFilter}&${idaX11DeviceTypeFilter}` : `/sim-management/sim-device-map?&${activatedSortFilter}$${idaX11DeviceTypeFilter}`
         return <a style={{textDecoration: 'none'}} href={activatedLink}>{data.type}</a>;
      
      case 'Decommissioned':
          const decommissionedLink = data.buidId ? `/sim-management/decommissioned-controllers?buid=${data.buidId}&${idaX11ControllerTypeFilter}` : `/sim-management/decommissioned-controllers?${idaX11ControllerTypeFilter}`
          return <a style={{textDecoration: 'none'}} href={decommissionedLink}>{data.type}</a>;
      
          default:
        return data.type;
    }
  }

  const PercentNumIcon = () => {
    return <Icon
      name={showPercent ? 'fa-hashtag' : 'fa-percent'}
      onClick={toggleShowPercent} />
  }

  const Circle = (
    props: {
      item: { percent: number, color: string },
      buid: string,
      idx: number
    }
  ) => {
    const percent = props.item.percent
    return (
      <circle
        className="donut-slice"
        strokeDasharray={`${percent} ${100 - percent}`}
        strokeDashoffset={getStrokeOffset(props.idx, getSortedData(props.buid))}
        stroke={props.item.color}
        cx="20"
        cy="20"
        r="16" />
    )
  }

  return (
    <TwCard className={classNames('controllers-data-sources-widget', props.className)}>
      <TwCardHeader className="justify-between">
        <div>{t('ui_widget_header_idax_lifecycle')}</div>
        <PercentNumIcon />
      </TwCardHeader>
      <TwCardBody className="flex-col gap-4 cdsw-body">
        <RenderIf if={data}>
          <WidgetScroller
            rememberSlide={true}
            className="h-full"
            name={'idax-lifecycle-widget'}
            onSlideTitle={onSlideTitle}>
            {distinctBuids.map((buid, i) => (
              <div
                key={i}
                title={buid ?? ''}
                className="flex flex_1 datawrapper">
                <svg
                  className="chart"
                  viewBox="0 0 40 40">
                  {getSortedData(buid ?? '').map((item, j) => (
                    <Circle
                      key={j}
                      item={item}
                      buid={buid ?? ''}
                      idx={j} />
                  ))}
                </svg>
                <div className="typewrapper flex column jcaround">
                  {getSortedData(buid ?? '').map((data, j) => (
                    <div key={j}>
                      <RenderIf if={!showPercent || data.percent}>
                        <div className="flex jsb row">
                          <div
                            className="circle"
                            style={{
                              background: data.color,
                              border: `1px solid ${data.color}`
                            }} />
                              <RenderIf if={data.count}>
                          <div className="sourcetype">
                            <p>{createTypeLink(data)}</p>
                          </div>
                          </RenderIf>
                          <RenderIf if={showPercent && data.percent}>
                            <div className="percentage">
                              {round(data.percent)} %
                            </div>
                          </RenderIf>
                          <RenderIf if={!showPercent}>
                            <div className="percentage">
                              {data.count}
                            </div>
                          </RenderIf>
                        </div>
                      </RenderIf>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </WidgetScroller>
        </RenderIf>
        <RenderIf if={loading}>
          <div className="flex flex_1 datawrapper">
            <div className="chart">
            </div>
            <div className="typewrapper flex column jcaround">
              {(Array(NumberOfTypesToShow).fill(null).map((_, i) => (
                <div
                  key={i}
                  className="flex jsb row">
                  <div
                    className="circle"
                    style={{
                      background: '#efefef',
                      border: '1px solid #efefef'
                    }} />
                  <div className="sourcetype">
                    <PlaceholderText />
                  </div>
                  <div className="percentage">
                    <PlaceholderText />
                  </div>
                </div>
              )))}
            </div>
          </div>
        </RenderIf>
      </TwCardBody>
      <RenderIf if={!isMobile && (loading || data)}>
        <TwCardFooter className="justify-end">
          <RenderIf if={loading}>
            <PlaceholderText />
          </RenderIf>
          <RenderIf if={data && features.reportIdaLifecycle.read}>
            <a
              className="no-underline flex gap-2"
              href={'/reports/idalifecycle'}>
             {t('ui_widget_idax_lifecycle_link_to_report')}
              <Icon name={CustomIcon.ARROW_RIGHT} />
            </a>
          </RenderIf>
        </TwCardFooter>
      </RenderIf>
    </TwCard>
  )
}
export default IdaxLifecycleWidget
