import React, {FC, useMemo, useState} from "react";
import './tank-low-level-widget.css'
import {useCaseInsensitiveTranslation} from "$lib/hooks/case-insensitive-translation";
import PlaceholderText from "$components/placeholders/placeholder-text/placeholder-text.react";
import Icon from "$components/icons/icon/icon.react";
import {CustomIcon} from "$components/icons/icon/CustomIcon";
import {useQuery} from "$lib/hooks/fetch-utillities";
import {TankLowLevelWidgetQueryDocument, TankLowLevelWidgetQueryQuery} from "$typings/graphql-codegen";
import {SortDirection} from "$typings/graphql";
import {ColumnAccess} from "../../../../../config";
import {addNotExistingDefaultFilters} from "$pages/sitelistpage-react/modules/sitelist-utils";
import {IFilterGroup} from "$interfaces/iFilter";
import RenderIf from "$components/render-if/render-if";
import Button from "$components/buttons/button.react";
import SiteChannelBar from "$components/bars/site-channel-bar/site-channel-bar.react";
import {useIsMobile} from "$lib/hooks/isMobile";
import classNames from "classnames";
import TwCard from "$components/cards/tw-card/tw-card";
import TwCardHeader from "$components/cards/tw-card/tw-card-header";
import TwCardBody from "$components/cards/tw-card/tw-card-body";
import TwCardFooter from "$components/cards/tw-card/tw-card-footer";

type Tank = {
  siteChannelId?: number | null,
  percentage?: number | null,
  lastSample?: number | null,
  maximum?: number | null,
  minimum?: number | null,
  capacity?: number | null,
  lastSampleTime?: string | null,
  tankDetails?: {
    reachMinimum?: string | null
  } | null
}

type TankSite = TankLowLevelWidgetQueryQuery['elasticSearchPages']['sitelist']['data']['edges'][0]

interface TankSiteWithLowestTank extends TankSite {
  lowestTank?: Tank
}

const TankLowLevelWidget: FC<React.HTMLAttributes<HTMLElement>> = ({className}) => {
  const [t] = useCaseInsensitiveTranslation()
  const isMobile = useIsMobile()

  const Variables = {
    sortDirection: SortDirection.Asc,
    sortProperty: ColumnAccess.TankPercentFull,
    numberToShow: 4,
    filters: JSON.stringify(
      addNotExistingDefaultFilters(
        createFilter()
      )
    )
  }
  
  const SiteListURL = (
    `/sitelistpage?tankIsParked=false&sortProperty=${Variables.sortProperty}&sortDirection=${Variables.sortDirection}`
  )
  
  const [error, setError] = useState<string | undefined>()

  const {
    data,
    loading,
    revalidate
  } = useQuery<TankLowLevelWidgetQueryQuery>(
    TankLowLevelWidgetQueryDocument,
    Variables,
    undefined,
    undefined,
    (e) => setError(e)
  )

  /**
   * # Get tank with lowest percentage.
   * @param a Tank A.
   * @param b Tank B.
   * @returns The tank with the lowest percentage.
   */
  function getTankWithLowestPercentage(a: Tank, b: Tank): Tank {
    return (a.percentage ?? 0) < (b.percentage ?? 0) ? a : b
  }

  /**
   * # Convert regular `TankSite` object into `TankSiteWithLowestTank` object
   * @param tankSite Tank site object
   * @returns Mutated tank site object
   * @see TankSiteWithLowestTank
   */
  function intoTankSiteWithLowestTank(tankSite: TankSite): TankSiteWithLowestTank {
    const tankChannels = tankSite?.tankChannels ?? [] as Tank[]
    const lowestTank = tankChannels.reduce(getTankWithLowestPercentage)
    return {
      ...tankSite,
      lowestTank
    }
  }

  /**
   * Mutated array of tank sites.
   * @see TankSiteWithLowestTank
   */
  const tankSitesWithLowestTank = useMemo<TankSiteWithLowestTank[]>(
    () => {
      if (loading) return []
      const tankSites = data?.elasticSearchPages?.sitelist?.data?.edges ?? []
      return tankSites.map(intoTankSiteWithLowestTank)
    },
    [data, loading]
  )
  
  function createFilter(): IFilterGroup[] {
    return [{
      field: 'tankisparked',
      type: 'boolean',
      exclude: true,
      filters:[{value: true}]
    }]
  }
  
  function createTankDetailsURL(item: TankSite, tank?: Tank) {
    if (tank?.siteChannelId) {
      return `/sitedetails/${item.siteId}/tanks/tankDetails/${tank?.siteChannelId}`
    } else {
      return `/sitedetails/${item.siteId}/tanks`
    }
  }
  
  function reFetch() {
    (revalidate && revalidate())
  }
  
  return (
    <TwCard className={classNames('tank-low-level-widget', className)}>
      <TwCardHeader>{t('UI_Widget_Header_Lowest_Levels')}</TwCardHeader>
      <TwCardBody className="flex-col gap-4">
        <RenderIf if={loading}>
          <div className="fill-height jcaround flex column">
            <div className="row">
              <div className="tankbar">
                <PlaceholderText />
              </div>
              <div className="sitename">
                <PlaceholderText />
              </div>
            </div>
          </div>
        </RenderIf>
        <RenderIf if={data}>
          <div className="grow flex flex-col grow gap-4">
            {tankSitesWithLowestTank.map((tankSite, siteIdx) => (
              <div key={siteIdx}>
                <RenderIf if={!tankSite.lowestTank}>
                  <div className="tankbar" />
                </RenderIf>
                <RenderIf if={tankSite.lowestTank}>
                  <div className="row">
                    <SiteChannelBar
                      className="tankbar"
                      lastSample={tankSite.lowestTank?.lastSample}
                      maximum={tankSite.lowestTank?.maximum}
                      minimum={tankSite.lowestTank?.minimum}
                      percentage={tankSite.lowestTank?.percentage}
                      capacity={tankSite.lowestTank?.capacity}
                      lastSampleTime={tankSite.lowestTank?.lastSampleTime}
                      reachMinimum={tankSite.lowestTank?.tankDetails?.reachMinimum}
                    />
                    <div className="sitename trunc-text">
                      <RenderIf if={tankSite}>
                        <a
                          href={createTankDetailsURL(tankSite, tankSite.lowestTank)}
                          className="no-text-decoration">
                          {tankSite?.alias}
                        </a>
                      </RenderIf>
                    </div>
                  </div>
                </RenderIf>
              </div>
            ))}
          </div>
        </RenderIf>
        <RenderIf if={error && !loading}>
          <div className="flex column jccenter aicenter flex_1">
            <div>
              {t('UI_Dashboard_Item_ErrorLoadingData')}
            </div>
            <Button
              variant={'tertiary'}
              onClick={reFetch}>
              Try again
            </Button>
          </div>
        </RenderIf>
      </TwCardBody>
      <RenderIf if={!isMobile && (loading || data)}>
        <TwCardFooter className="justify-end">
          <RenderIf if={loading}>
            <PlaceholderText/>
          </RenderIf>
          <RenderIf if={data}>
            <a
              className="no-underline flex gap-2"
              href={SiteListURL}>
              Open in sitelist
              <Icon name={CustomIcon.ARROW_RIGHT} />
            </a>
          </RenderIf>
        </TwCardFooter>
      </RenderIf>
    </TwCard>
  )
}

export default TankLowLevelWidget
