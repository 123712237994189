export enum DashboardItemNameEnum {
  Shortcuts = 'UI_Dashboard_Item_Shortcuts',
  Sitelist = 'UI_Dashboard_Item_Sitelist',
  SitelistMap = 'UI_Dashboard_Item_SitelistMap',
  SitelistOnlineSites = 'UI_Dashboard_Item_SitelistOnlineSites',
  SitelistOfflineSites = 'UI_Dashboard_Item_SitelistOfflineSites',
  SitelistParkedSites = 'UI_Dashboard_Item_SitelistParkedSites',
  SitelistVirtualSites = 'UI_Dashboard_Item_SitelistVirtualSites',
  SitelistActiveAlarms = 'UI_Dashboard_Item_SitelistActiveAlarms',
  // SitelistSearch = 'UI_Dashboard_Item_SitelistSearch', // Not sure what this is
  LiveMap = 'ui_dashboard_item_livemap',
  Releasenotes = 'UI_Navbar_ReleaseNotes',

  QuickSearch = 'UI_Dashboard_Item_Quicksearch',
  CustomerRef = 'UI_Dashboard_Item_CustomerRef',
  ShipTo = 'UI_Dashboard_Item_ShipTo',
  SoldTo = 'UI_Dashboard_Item_SoldTo',
  FreeText = 'UI_Dashboard_Item_FreeText',
  DeliveryNote = 'UI_Dashboard_Item_DeliveryNote',
  ChannelId = 'UI_Dashboard_Item_ChannelId',

  Widgets = 'UI_Dashboard_Item_Widgets',
  LowestLevels = 'UI_Dashboard_Item_LowestLewels',
  NextReachMinimum = 'UI_Dashboard_Item_NextReachMinimum',
  RecentlyViewedSites = 'UI_Dashboard_Item_RecentlyViewedSites',
  DeliveriesLast = 'UI_Dashboard_Item_DeliveriesLast',
  DeliveriesYTD = 'UI_Dashboard_Item_DeliveriesYTD',
  DataSources = 'UI_Dashboard_Item_DataSources',
  UserStatistics = 'UI_Dashboard_Item_UserStatistics',
  Controllers = 'UI_Dashboard_Item_Controllers',
  NewSites = 'UI_Dashboard_Item_NewSites',
  Lifecycle = 'UI_Dashboard_Item_Lifecycle',
  PinnesSites = 'UI_Dashboard_Item_PinnedSites'
}

export const GroupedDashboardItemNameEnum = {
  [DashboardItemNameEnum.Shortcuts]: [
    DashboardItemNameEnum.Shortcuts,
    DashboardItemNameEnum.Sitelist,
    DashboardItemNameEnum.SitelistMap,
    DashboardItemNameEnum.SitelistOnlineSites,
    DashboardItemNameEnum.SitelistOfflineSites,
    DashboardItemNameEnum.SitelistParkedSites,
    DashboardItemNameEnum.SitelistVirtualSites,
    DashboardItemNameEnum.SitelistActiveAlarms,
    DashboardItemNameEnum.LiveMap,
    DashboardItemNameEnum.Releasenotes,
  ],
  [DashboardItemNameEnum.QuickSearch]: [
    DashboardItemNameEnum.QuickSearch,
    DashboardItemNameEnum.CustomerRef,
    DashboardItemNameEnum.ShipTo,
    DashboardItemNameEnum.SoldTo,
    DashboardItemNameEnum.FreeText,
    DashboardItemNameEnum.DeliveryNote,
    DashboardItemNameEnum.ChannelId,
  ],
  [DashboardItemNameEnum.Widgets]: [
    DashboardItemNameEnum.Widgets,
    DashboardItemNameEnum.LowestLevels,
    DashboardItemNameEnum.NextReachMinimum,
    DashboardItemNameEnum.RecentlyViewedSites,
    DashboardItemNameEnum.DeliveriesLast,
    DashboardItemNameEnum.DeliveriesYTD,
    DashboardItemNameEnum.DataSources,
    DashboardItemNameEnum.UserStatistics,
    DashboardItemNameEnum.Controllers,
    DashboardItemNameEnum.NewSites,
    DashboardItemNameEnum.Lifecycle
  ],
  [DashboardItemNameEnum.PinnesSites]: [
    DashboardItemNameEnum.PinnesSites
  ]
}
