import { DashboardItemNameEnum } from "$pages/dashboard/dashboard-edit/DashboardItemNameEnum";
import useItemAccessRecord from "$pages/dashboard/hooks/useItemAccessRecord";
import usePersistedItemVisibility from "$pages/dashboard/hooks/usePersistedItemVisibility";

export default function useWidgetVisibility() {
  const { items } = usePersistedItemVisibility()
  const accessRecord = useItemAccessRecord().Widgets

  const hasAccess = (access: boolean, item?: DashboardItemNameEnum): boolean => {
    if (item !== undefined) return access && items.includes(item)
    return access
  }

  return {
    lowestLevels: hasAccess(accessRecord.LowestLevels, DashboardItemNameEnum.LowestLevels),
    nextReachMinimum: hasAccess(accessRecord.NextReachMinimum, DashboardItemNameEnum.NextReachMinimum),
    recentlyViewed: hasAccess(accessRecord.RecentlyViewedSites, DashboardItemNameEnum.RecentlyViewedSites),
    deliveriesLast: hasAccess(accessRecord.LastDeliveries, DashboardItemNameEnum.DeliveriesLast),
    deliveriesYTD: hasAccess(accessRecord.DeliveriesYTD, DashboardItemNameEnum.DeliveriesYTD),
    dataSources: hasAccess(accessRecord.DataSources, DashboardItemNameEnum.DataSources),
    newSites: hasAccess(accessRecord.NewSites, DashboardItemNameEnum.NewSites),
    userStatistics: hasAccess(accessRecord.UserStatistics, DashboardItemNameEnum.UserStatistics),
    controllers: hasAccess(accessRecord.Controllers, DashboardItemNameEnum.Controllers),
    lifecycle: hasAccess(accessRecord.Lifecycle, DashboardItemNameEnum.Lifecycle)
  }
}
