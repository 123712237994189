import { useQuery } from "$lib/hooks/fetch-utillities";
import { ChannelIdQuickSearchDocument } from "$typings/graphql-codegen";
import { useMemo } from "react";
import useChannelIdQuickSearchVariables from "./useChannelIdQuickSearchVariables";
import { usePersistedState } from "$lib/hooks/usePersistedState";

export default function useChannelIdQuickSearchQuery() {
  const CacheKey: string = 'quickSearchChannelIdKey'
  const [searchValue, setSearchValue] = usePersistedState(CacheKey, '')
  const variables = useChannelIdQuickSearchVariables(searchValue)
  const {
    data,
    loading
  } = useQuery(
    ChannelIdQuickSearchDocument,
    variables
  )

  const mutatedData = useMemo(
    () => {
      const channel = data?.channelByChannelId;
      const noData = !channel;
      if (noData) return undefined
      
      return {
        channelAlias: channel?.alias ?? '',
        channelType: channel?.channelType ?? '',
        siteId: channel?.controller?.siteId ?? 0,
        channelId: channel?.channelId ?? 0,
        controllerId: channel?.controller.controllerId ?? 0,
      }
    },
    [data, loading]
  )

  const hasSearchValue = useMemo(
    () => !!searchValue && searchValue.length > 0,
    [searchValue]
  )
  
  const hasChannel = useMemo(() => {
    const channelId = data?.channelByChannelId?.channelId
    return !!channelId
  }, [data])
  
  const showResult = useMemo(() => {
    return !loading && hasSearchValue && hasChannel
  }, [hasChannel, hasSearchValue])
  
  const showNoMatch = useMemo(() => {
    return !loading && hasSearchValue && !hasChannel
  }, [hasChannel])

  const siteListUrl = useMemo(
    () => mutatedData?.siteId ? `/sitedetails/${mutatedData?.siteId}/channels` : `/controllermanager/${mutatedData?.controllerId}/channels`,
    [mutatedData]
  )

  return {
    data,
    mutatedData,
    loading,
    showResult,
    showNoMatch,
    siteListUrl,
    searchValue,
    setSearchValue,
  }
}
