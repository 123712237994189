import DatePicker from "$components/date-picker/date-picker.react";
import Icon from "$components/icons/icon/icon.react";
import TextField from "$components/textbox/text-field.react";
import { formatDateTime } from "$lib/dateHelpers";
import { useCaseInsensitiveTranslation } from "$lib/hooks/case-insensitive-translation";
import { SearchMode } from "$pages/dashboard/quicksearch/deliverynote-quicksearch/SearchMode";
import React, { FC, useMemo } from 'react';
import StatelessQuickSearchWidget from '../common/stateless-quick-search-widget';
import './delivery-note-quick-search.css';
import useDeliveryNoteQuickSearchQuery from './hooks/useDeliveryNoteQuickSearchQuery';

export interface DeliveryNoteQuickSearchProps {}

const DeliveryNoteQuickSearch: FC<DeliveryNoteQuickSearchProps> = () => {
  const [t] = useCaseInsensitiveTranslation()
  const Header = t('UI_Dashboard_Quicksearch_DeliveryNote')

  const {
    loading,
    mutatedData,
    showResult,
    showNoMatch,
    freetextUrl,
    dateUrl,
    searchValue,
    setSearchValue,
    dateValue,
    setDateValue,
    searchMode,
    setSearchMode,
  } = useDeliveryNoteQuickSearchQuery()
  
  const commonUrl: string = useMemo(() => {
    switch (searchMode) {
      case SearchMode.Text: return freetextUrl
      case SearchMode.Date: return dateUrl
      default: return ''
    }
  }, [searchMode, freetextUrl, dateUrl])
  
  function onFreeTextChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value: string = e.target.value
    setSearchValue(value)
  }
  
  function onDateChange(date: Date) {
    let dateString: string = ''
    if (date) {
      const format: string = 'yyyy-MM-dd'
      const timezone: undefined = undefined
      dateString = formatDateTime(date, format, timezone)
    }
    setDateValue(dateString)
  }
  
  function inTextMode(): boolean {
    return searchMode === SearchMode.Text
  }
  
  function onClickTextInputElement(): void {
    setSearchMode(SearchMode.Text)
  }
  
  function onClickDateInputElement(): void {
    setSearchMode(SearchMode.Date)
  }

  function onDateClear(): void {
    setDateValue(new Date().toISOString())
  }
  
  const SearchIcon = <Icon name="fa-search" />
  
  const SearchBar: JSX.Element = (
    <div className="flex w-full dnqs gap-x-1">
      <div
        className="flex bg-gray-100"
        data-open={String(inTextMode())}
        onClick={onClickTextInputElement}>
        <TextField
          value={searchValue}
          icon={SearchIcon}
          disabled={searchMode === SearchMode.Date}
          placeholder={t('UI_Dashboard_Quicksearch_DeliveryNote')}
          onChange={onFreeTextChange}>
        </TextField>
      </div>
      <div
        data-open={String(!inTextMode())}
        onClick={onClickDateInputElement}>
        <DatePicker
          style={{width: '100%'}}
          disabled={searchMode === SearchMode.Text}
          selectedDate={!!dateValue ? dateValue : undefined}
          onChanged={onDateChange}
          onClick={onClickDateInputElement}
          onClear={onDateClear}
          placeholder={t('UI_Common_Date')}>
        </DatePicker>
      </div>
    </div>
  )
  
  const SearchResult: JSX.Element = (
    <a
      className="no-underline"
      href={commonUrl}>
      <p className="truncate">
        {mutatedData?.displayName}
      </p>
    </a>
  )

  return (
    <StatelessQuickSearchWidget
      header={Header}
      searchComponent={SearchBar}
      isLoading={loading}
      showResult={showResult}
      showNoMatch={showNoMatch}
      searchResultComponent={SearchResult}
      dataCount={mutatedData?.totalCount ?? 0}
      url={commonUrl} />
  )
}

export default DeliveryNoteQuickSearch
