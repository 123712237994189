import React, {FC, HTMLAttributes} from 'react'
import CardHeader from "$components/cards/card-header/card-header.react"
import {useDashboardEditContext} from "../context/dashboard-edit-context"
import classNames from "classnames";

const DashboardEditHeader: FC<HTMLAttributes<HTMLElement>> = (props) => {
  const ctx = useDashboardEditContext()
  const className = classNames('p-4', props.className)
  return <CardHeader className={className}>{ctx.title}</CardHeader>
}

export default DashboardEditHeader
