import { useQuery } from "$lib/hooks/fetch-utillities";
import { usePersistedState } from "$lib/hooks/usePersistedState";
import { CustomerRefQuickSearchDocument } from "$typings/graphql-codegen";
import { useMemo } from "react";
import useCustomerRefQuickSearchVariables from "./useCustomerRefQuickSearchVariables";

export default function useCustomerRefQuickSearchQuery() {
  const CacheKey = 'quickSearchCustomerKey'
  const [searchValue, setSearchValue] = usePersistedState(CacheKey, '')
  const variables = useCustomerRefQuickSearchVariables(searchValue)
  const { data, loading } = useQuery(
    CustomerRefQuickSearchDocument,
    variables
  )

  const mutatedData = useMemo(() => {
    const siteListData = data?.elasticSearchPages.sitelist.data
    const edges = siteListData?.edges ?? []
    if (edges.length < 1) return undefined
    const site = siteListData?.edges[0]
    const tankChannels = siteListData?.edges[0].tankChannels ?? []
    const tank = tankChannels[0]

    return {
      lastSample: tank?.lastSample ?? null,
      percentage: tank?.percentage ?? null,
      minimum: tank?.minimum ?? null,
      maximum: tank?.maximum ?? null,
      capacity: tank?.capacity ?? null,
      lastSampleTime: tank?.lastSampleTime ?? null,
      reachMinimum: tank?.tankDetails?.reachMinimum,
      alias: site?.alias,
      siteId: site?.siteId,
      totalCount: siteListData?.totalCount ?? null
    }
  }, [data, loading])

  const hasSearchValue = useMemo(() => {
    return !!searchValue && searchValue.length > 0
  }, [searchValue])

  const hasData = useMemo(() => {
    const siteList = data?.elasticSearchPages.sitelist
    const edges = siteList?.data.edges ?? []
    const [site] = edges
    return edges.length > 0 && !!site
  }, [data])

  const showResult = useMemo(
    () => !loading && hasSearchValue && hasData,
    [loading, hasSearchValue, hasData]
  )

  const showNoMatch = useMemo(
    () => !loading && hasSearchValue && !hasData,
    [loading, hasSearchValue, hasData]
  )

  const siteUrl = useMemo(() => {
    const id = mutatedData?.siteId ?? ''
    return id ? `sitedetails/${id}` : ''
  }, [mutatedData])

  const siteListUrl = useMemo(() => {
    return searchValue ? `sitelistpage?customerRef=${searchValue}` : ''
  }, [searchValue])

  return {
    data,
    mutatedData,
    loading,
    showResult,
    showNoMatch,
    siteUrl,
    siteListUrl,
    searchValue,
    setSearchValue,
  }
}