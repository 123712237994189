import { ISession } from "$interfaces/application";
import { getAllowedColumns } from "$pages/common";
import { UserFeatures, getSession, getUserFeatures } from "../../../config/sessionService"

export type ItemAccessRecord = {
  Shortcuts: {
    SiteList: boolean,
    SiteListMap: boolean,
    SiteListOnline: boolean,
    SiteListOffline: boolean,
    SiteListParked: boolean,
    SiteListVirtual: boolean,
    SiteListActiveAlarms: boolean,
    Search: boolean,
    LiveMap: boolean,
    ReleaseNotes: boolean,
  },
  QuickSearch: {
    CustomerReference: boolean,
    ShipTo: boolean,
    SoldTo: boolean,
    Freetext: boolean,
    DeliveryNote: boolean,
    ChannelId: boolean,
  },
  Widgets: {
    LowestLevels: boolean,
    NextReachMinimum: boolean,
    RecentlyViewedSites: boolean,
    LastDeliveries: boolean,
    DeliveriesYTD: boolean,
    DataSources: boolean,
    UserStatistics: boolean,
    Controllers: boolean,
    NewSites: boolean,
    Lifecycle: boolean
  },
  PinnedSites: {
    PinnedSites: boolean,
  }
}

export default function useItemAccessRecord(): ItemAccessRecord {
  const session: ISession = getSession()
  const features: UserFeatures = getUserFeatures()
  const columns = getAllowedColumns(session)

  const isSuperUser: boolean = session.currentUser.isSuperUser === true
  const baseAccess: boolean = features.dashboard.read

  function hasBaseAccess(): boolean {
    return isSuperUser || baseAccess
  }

  function hasBaseAccessAnd(additionalAccess: boolean): boolean {
    return isSuperUser || (baseAccess && additionalAccess)
  }

  return {
    Shortcuts: {
      SiteList: hasBaseAccess(),
      SiteListMap: hasBaseAccess(),
      SiteListOnline: hasBaseAccess(),
      SiteListOffline: hasBaseAccess(),
      SiteListParked: hasBaseAccess(),
      SiteListVirtual: hasBaseAccess(),
      SiteListActiveAlarms: hasBaseAccessAnd(features.alarms.read),
      Search: hasBaseAccess(),
      LiveMap: hasBaseAccessAnd(features.liveMap.read),
      ReleaseNotes: hasBaseAccess(),
    },
    QuickSearch: {
      CustomerReference: hasBaseAccessAnd(columns.CustomerRef),
      ShipTo: hasBaseAccessAnd(columns.ShipTo),
      SoldTo: hasBaseAccessAnd(columns.SoldTo),
      Freetext: hasBaseAccess(),
      DeliveryNote: hasBaseAccessAnd(features.deliveryNoteManager.read),
      ChannelId: hasBaseAccessAnd(columns.ChannelId),
    },
    Widgets: {
      LowestLevels: hasBaseAccessAnd(features.dashboardWidgetLowestLevels.read),
      NextReachMinimum: hasBaseAccessAnd(features.dashboardWidgetNextReachMinimum.read),
      RecentlyViewedSites: hasBaseAccessAnd(features.dashboardWidgetRecentlyViewedSites.read),
      LastDeliveries: hasBaseAccessAnd(features.dashboardWidgetDeliveriesLast.read),
      DeliveriesYTD: hasBaseAccessAnd(features.dashboardWidgetDeliveriesYTD.read),
      DataSources: hasBaseAccessAnd(features.dashboardWidgetDataSources.read),
      UserStatistics: hasBaseAccessAnd(features.dashboardWidgetUserStatistics.read),
      Controllers: hasBaseAccessAnd(features.dashboardWidgetControllers.read),
      NewSites: hasBaseAccessAnd(features.dashboardWidgetNewSites.read),
      Lifecycle: hasBaseAccessAnd(features.dashboardWidgetLifecycle.read)
    },
    PinnedSites: {
      PinnedSites: hasBaseAccess()
    }
  }
}
