import CollapsableSection from "$components/collapsable-section/collapsable-section.react";
import RenderIf from "$components/render-if/render-if";
import { useCaseInsensitiveTranslation } from "$lib/hooks/case-insensitive-translation";
import ControllerReceivingPlacesWidget from "$pages/dashboard/widgets/modules/controller-receiving-places-widget/controller-receiving-places-widget";
import ControllersDataSourcesWidget from "$pages/dashboard/widgets/modules/controllers-data-sources-widget/controllers-data-sources-widget";
import DeliveriesLastWidget from "$pages/dashboard/widgets/modules/deliveries-last-widget/deliveries-last-widget";
import DeliveriesYearToDateWidget from "$pages/dashboard/widgets/modules/deliveries-year-to-date-widget/deliveries-year-to-date-widget";
import NewSitesWidget from "$pages/dashboard/widgets/modules/new-sites-widget/new-sites-widget";
import SitesRecentlyViewedWidget from "$pages/dashboard/widgets/modules/sites-recently-viewed-widget/sites-recently-viewed-widget";
import TankLowLevelWidget from "$pages/dashboard/widgets/modules/tank-low-level-widget/tank-low-level-widget.react";
import TankReachMinimumWidget from "$pages/dashboard/widgets/modules/tank-reach-minimum-widget/tank-reach-minimum-widget";
import UserStatisticsWidget from "$pages/dashboard/widgets/modules/user-statistics-widget/user-statistics-widget.react";
import React, { FC } from "react";
import { DashboardCollapsables } from "../DashboardCollapsables";
import useWidgetVisibility from "./hooks/useWidgetVisibility";
import './widgets.css';
import IdaxLifecycleWidget from "./modules/idax-lifecycle-widget/idax-lifecycle-widget";

interface WidgetsProps {}

const Widgets: FC<WidgetsProps> = (_props: WidgetsProps) => {
  const [t] = useCaseInsensitiveTranslation()
  const visibility = useWidgetVisibility()
  const someAreVisible = Object.values(visibility).some((v: boolean) => v === true)
  
  return (
    <RenderIf if={someAreVisible}>
      <CollapsableSection
        header={t('UI_Dashboard_Widgets_Header')}
        persistState={true}
        name={'dashboard-' + DashboardCollapsables.WIDGETS}>
        <div className="widgets-wrapper">
          <RenderIf if={visibility.lowestLevels}>
            <TankLowLevelWidget className="widget" />
          </RenderIf>
          
          <RenderIf if={visibility.nextReachMinimum}>
            <TankReachMinimumWidget className="widget" />
          </RenderIf>
          
          <RenderIf if={visibility.recentlyViewed}>
            <SitesRecentlyViewedWidget className="widget" />
          </RenderIf>
          
          <RenderIf if={visibility.deliveriesLast}>
            <DeliveriesLastWidget className="widget" />
          </RenderIf>
          
          <RenderIf if={visibility.deliveriesYTD}>
            <DeliveriesYearToDateWidget className="widget" />
          </RenderIf>
          
          <RenderIf if={visibility.dataSources}>
            <ControllersDataSourcesWidget className="widget" />
          </RenderIf>

          <RenderIf if={visibility.userStatistics}>
            <UserStatisticsWidget className="widget"/>
          </RenderIf>
          
          <RenderIf if={visibility.controllers}>
            <ControllerReceivingPlacesWidget className="widget" />
          </RenderIf>
          
          <RenderIf if={visibility.newSites}>
            <NewSitesWidget className="widget" />
          </RenderIf>

          <RenderIf if={visibility.lifecycle}>
            <IdaxLifecycleWidget className="widget" />
          </RenderIf>
        </div>
      </CollapsableSection>
    </RenderIf>
  )
}

export default Widgets
