import Card from "$components/cards/card/card.react";
import CollapsableSection from "$components/collapsable-section/collapsable-section.react";
import Icon from "$components/icons/icon/icon.react";
import RenderIf from "$components/render-if/render-if";
import Tooltip from "$components/tooltip/tooltip.react";
import { useCaseInsensitiveTranslation } from "$lib/hooks/case-insensitive-translation";
import { useIsMobile } from "$lib/hooks/isMobile";
import { useShortcutsVisibility } from "$pages/dashboard/shortcuts/hooks/useShortcutsVisibility";
import React, { FC } from "react";
import { DashboardCollapsables } from "../DashboardCollapsables";
import useShortcutsQuery from "./hooks/useShortcutsQuery";
import './shortcuts.css';

export interface ShortcutsProps {}

const Shortcuts: FC<ShortcutsProps> = () => {
  const [t] = useCaseInsensitiveTranslation()
  const isMobile = useIsMobile()
  const visibility = useShortcutsVisibility()
  const someAreVisible = Object.values(visibility).some((v: boolean) => v === true)

  const {
    counts,
    loading
  } = useShortcutsQuery()
  
  return ( 
    <RenderIf if={someAreVisible}>
      <CollapsableSection
        header={t('UI_Dashboard_Shortcuts_Header')}
        persistState={true}
        name={'dashboard-' + DashboardCollapsables.SHORTCUTS}>
        <div className="shortcuts-wrapper">
          <RenderIf if={loading}>
              <p>Loading ...</p>
          </RenderIf>
          
          <RenderIf if={!loading}>            
            <RenderIf if={visibility.siteList}>
              <Tooltip text={t('UI_Shortcuts_Go_To_Sitelist')}>
                <a className="no-text-decoration" href="sitelistpage">
                  <Card>
                    <Icon name="fa-list-alt"></Icon>
                  </Card>
                </a>
              </Tooltip>
            </RenderIf>
            
            <RenderIf if={visibility.siteListMap && !isMobile}>
              <Tooltip text={t('UI_Shortcuts_Go_To_Sitelist_Map')}>
                <a className="no-text-decoration" href="sitelistpage?showMap=true">
                  <Card>
                    <Icon name="map"></Icon>
                  </Card>
                </a>
              </Tooltip>
            </RenderIf>
            
            <RenderIf if={visibility.siteListOnlineSites}>
              <Tooltip text={t('UI_Shortcuts_Go_To_Sitelist_Online_Sites')}>
                <a className="no-text-decoration" href="sitelistpage?onlineSites=true">
                  <Card>
                    <Icon name="signal-good"></Icon>
                    <p>{counts.onlineSitesCount}</p>
                  </Card>
                </a>
              </Tooltip>
            </RenderIf>
            
            <RenderIf if={visibility.siteListOfflineSites}>
              <Tooltip text={t("UI_Shortcuts_Go_To_Sitelist_Offline_Sites")}>
                <a className="no-text-decoration" href="sitelistpage?offlineSites=true">
                  <Card>
                    <Icon name="signal-bad"></Icon>
                    <p>{counts.offlineSitesCount}</p>
                  </Card>
                </a>
              </Tooltip>
            </RenderIf>
            
            <RenderIf if={visibility.siteListParkedSites}>
              <Tooltip text={t('UI_Shortcuts_Go_To_Sitelist_Parked_Sites')}>
                <a className="no-text-decoration" href="sitelistpage?parkedSites=true">
                  <Card>
                    <Icon name="parked"></Icon>
                    <p>{counts.parkedSitesCount}</p>
                  </Card>
                </a>
              </Tooltip>
            </RenderIf>
            
            <RenderIf if={visibility.siteListVirtualSites}>
              <Tooltip text={t('UI_Shortcuts_Go_To_Sitelist_Virtual_Sites')}>
                <a className="no-text-decoration" href="sitelistpage?virtualSites=true">
                  <Card>
                    <Icon name="cloud"></Icon>
                    <p>{counts.virtualSitesCount}</p>
                  </Card>
                </a>
              </Tooltip>
            </RenderIf>
            
            <RenderIf if={visibility.siteListActiveAlarmsSites}>
              <Tooltip text={t('UI_Shortcuts_Go_To_Sitelist_Active_Alarms')}>
                <a className="no-text-decoration" href="sitelistpage?activeAlarms=true">
                  <Card>
                    <span style={{color: 'red'}}><Icon name="fa-bell"></Icon></span>
                    <p>{counts.activeAlarmsSitesCount}</p>
                  </Card>
                </a>
              </Tooltip>
            </RenderIf>
            
            <RenderIf if={visibility.liveMap}>
              <Tooltip text={t('UI_Shortcuts_Go_To_LiveMap')}>
                <a className="no-text-decoration" href="/livemap">
                  <Card>
                    <Icon name="fa-globe"></Icon>
                    <p>live</p>
                  </Card>
                </a>
              </Tooltip>
            </RenderIf>
            
            <RenderIf if={visibility.releaseNotes}>
              <Tooltip text={t('UI_Navbar_ReleaseNotes')}>
                <a className="no-text-decoration" href="/releasenotes">
                  <Card>
                    <Icon className="larger-icon" name="fa-question"></Icon>
                  </Card>
                </a>
              </Tooltip>
            </RenderIf>
          </RenderIf>
        </div>
      </CollapsableSection>
    </RenderIf>
  )
}

export default Shortcuts;
