import { formatDateTime } from "$lib/dateHelpers";
import { useQuery } from "$lib/hooks/fetch-utillities";
import { usePersistedState } from "$lib/hooks/usePersistedState";
import { DeliveryNoteQuickSearchDocument } from "$typings/graphql-codegen";
import { Dispatch, SetStateAction, useMemo } from "react";
import { SearchMode } from "../SearchMode";
import useDeliveryNoteQuickSearchVariables from "./useDeliveryNoteQuickSearchVariables";

type SearchModeState = [SearchMode, Dispatch<SetStateAction<SearchMode>>]

export default function useDeliveryNoteQuickSearchQuery() {
  const BaseURL: string = 'deliverynotemanager'
  
  const MainCacheKey: string = 'quickSearchDeliveryNoteKey'
  const DateCacheKey: string = 'quickSearchDeliveryNoteDateKey'
  const SearchModeCacheKey: string = 'quickSearchDeliveryNoteSearchModeKey'
  
  const DefaultSearchValue = ''
  const DefaultDateValue = (new Date()).toISOString()
  
  const [searchValue, setSearchValue] = usePersistedState(MainCacheKey, DefaultSearchValue)
  const [dateValue, setDateValue] = usePersistedState(DateCacheKey, DefaultDateValue)
  const [searchMode, setSearchMode] = usePersistedState(SearchModeCacheKey, SearchMode.Text) as SearchModeState
  
  const variables = useDeliveryNoteQuickSearchVariables(searchValue, dateValue, searchMode)
  const { data, loading } = useQuery(DeliveryNoteQuickSearchDocument, variables)
  
  const mutatedData = useMemo(() => {
    const deliveryNotes = data?.deliveryNotes
    if (!deliveryNotes) return undefined
    const [edge] = deliveryNotes?.edges ?? []
    return {
      totalCount: deliveryNotes?.totalCount ?? null,
      displayName: (edge?.siteName
        ? (edge?.siteName)
        : (edge?.fileName)) ?? null
    }
  }, [data, loading])

  const hasData: boolean = useMemo(() => {
    const edges = data?.deliveryNotes?.edges ?? []
    const n = edges.length ?? 0
    return n > 0
  }, [data])
  
  const hasSearchValue: boolean = useMemo(() => {
    switch (searchMode) {
      case SearchMode.Text: return !!searchValue && searchValue.length > 0
      case SearchMode.Date: return !!dateValue && dateValue.length > 0
    }
  }, [searchMode, searchValue, dateValue])
  
  const showResult: boolean = useMemo(() => {
    return !loading && hasSearchValue && hasData
  }, [loading, hasSearchValue, hasData])
  
  const showNoMatch: boolean = useMemo(() => {
    return !loading && hasSearchValue && !hasData
  }, [loading, hasSearchValue, hasData])

  const freetextUrl: string = useMemo(
    () => `${BaseURL}?freeText=${searchValue}`,
    [searchValue]
  )

  const dateUrl: string = useMemo(() => {
    const format = 'yyyy-MM-dd'
    const timeZone = undefined
    const dateString = formatDateTime(dateValue, format, timeZone)
    return `${BaseURL}?date=${dateString}`
  }, [dateValue])

  return {
    data,
    loading,
    mutatedData,
    showResult,
    showNoMatch,
    freetextUrl,
    dateUrl,
    searchValue,
    setSearchValue,
    dateValue,
    setDateValue,
    searchMode,
    setSearchMode
  }
}