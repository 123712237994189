import { useQuery } from "$lib/hooks/fetch-utillities";
import { ShortcutsQueryVariables } from "$typings/graphql";
import { ShortcutsQueryDocument } from "$typings/graphql-codegen";
import { useMemo } from "react";
import { useShortcutsVariables } from "./useShortcutsVariables";
import { ShortcutsVisibilityRecord, useShortcutsVisibility } from "./useShortcutsVisibility";

export type ShortcutsCountRecord = {
  onlineSitesCount: number | undefined,
  offlineSitesCount: number | undefined,
  parkedSitesCount: number | undefined,
  virtualSitesCount: number | undefined,
  activeAlarmsSitesCount: number | undefined,
}

export default function useShortcutsQuery() {
  const visibility: ShortcutsVisibilityRecord = useShortcutsVisibility()
  const variables: ShortcutsQueryVariables = useShortcutsVariables(visibility)
  const { data, loading } = useQuery(ShortcutsQueryDocument, variables)

  const counts = useMemo<ShortcutsCountRecord>(
    () => {
      const pages = data?.elasticSearchPages
      return {
        onlineSitesCount: pages?.onlineSitesCount?.data?.totalCount ?? 0,
        offlineSitesCount: pages?.offlineSitesCount?.data?.totalCount ?? 0,
        parkedSitesCount: pages?.parkedSitesCount?.data?.totalCount ?? 0,
        virtualSitesCount: pages?.virtualSitesCount?.data?.totalCount ?? 0,
        activeAlarmsSitesCount: pages?.activeAlarmsSitesCount?.data?.totalCount ?? 0
      }
    },
    [data, loading]
  )

  return {
    data,
    counts,
    loading
  }
}