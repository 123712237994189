import { useQuery } from "$lib/hooks/fetch-utillities";
import { FreetextQuickSearchDocument } from "$typings/graphql-codegen";
import { useMemo } from "react";
import { IFreetextQuickSearchResult } from "../IFreetextQuickSearchResult";
import useFreetextQuickSearchVariables from "./useFreetextQuickSearchVariables";
import { usePersistedState } from "$lib/hooks/usePersistedState";

export default function useFreetextQuickSearchQuery() {
  const CacheKey = 'quickSearchFreeTextKey'
  const [searchValue, setSearchValue] = usePersistedState(CacheKey, '')
  const variables = useFreetextQuickSearchVariables(searchValue)
  const { data, loading } = useQuery(
    FreetextQuickSearchDocument,
    variables
  )

  const mutatedData = useMemo<IFreetextQuickSearchResult | undefined>(
    () => {
      const siteListData = data?.elasticSearchPages.sitelist.data
      const nEdges = siteListData?.edges.length ?? 0
      if (nEdges < 1) return undefined
      const [edge] = (siteListData?.edges ?? [])
      const [tankChannel] = (edge?.tankChannels ?? [])
      
      return {
        lastSample: tankChannel?.lastSample ?? null,
        percentage: tankChannel?.percentage ?? null,
        minimum: tankChannel?.minimum ?? null,
        maximum: tankChannel?.maximum ?? null,
        capacity: tankChannel?.capacity ?? null,
        lastSampleTime: tankChannel?.lastSampleTime ?? null,
        reachMinimum: tankChannel?.tankDetails?.reachMinimum,
        alias: edge?.alias,
        siteId: edge?.siteId,
        totalCount: siteListData?.totalCount ?? null
      }
    },
    [data, loading]
  )

  const hasData = useMemo(() => {
    const siteList = data?.elasticSearchPages.sitelist
    const edges = siteList?.data.edges ?? []
    return edges.length > 0
  }, [data])
  
  const hasSearchValue = useMemo(
    () => !!searchValue && searchValue.length > 0,
    [searchValue]
  )

  const showResult = useMemo(() => {
    return !loading && hasSearchValue && hasData
  }, [loading, hasData, hasSearchValue])

  const showNoMatch = useMemo(() => {
    return !loading && hasSearchValue && !hasData
  }, [loading, hasData])

  const siteUrl = useMemo(() => {
    const siteId: string = mutatedData?.siteId ?? ''
    return siteId ? `sitedetails/${siteId}` : ''
  }, [mutatedData])

  const siteListUrl = useMemo(
    () => searchValue ? `sitelistpage?freeText=${searchValue}` : '',
    [searchValue]
  )

  return {
    data,
    mutatedData,
    loading,
    showResult,
    showNoMatch,
    siteUrl,
    siteListUrl,
    searchValue,
    setSearchValue,
  }
}
