import React, {FC, HTMLAttributes, useEffect, useMemo, useRef, useState} from 'react'
import './deliveries-chart.css'
import {
  DeliveriesBuidData
} from "$pages/dashboard/widgets/modules/deliveries-year-to-date-widget/deliveries-data";
import {useIsMobile} from "$lib/hooks/isMobile";
import {Bar, IBarChartOptions, IChartistBarChart, IChartistData} from "chartist";
import {useCaseInsensitiveTranslation} from "$lib/hooks/case-insensitive-translation";
import classNames from "classnames";

export interface DeliveriesChartProps extends HTMLAttributes<HTMLElement> {
  data: DeliveriesBuidData[]
  loading: boolean
}

const DeliveriesChart: FC<DeliveriesChartProps> = (props) => {
  const [t] = useCaseInsensitiveTranslation()
  const [barChart, setBarChart] = useState<IChartistBarChart>()
  const refChart = useRef<HTMLDivElement>(null)
  const isMobile = useIsMobile()
  
  const isReady = useMemo<boolean>(
    () => {
      const notLoading = !props.loading
      const hasData = !!props.data
      return notLoading && hasData
    },
    [
      props.loading,
      props.data,
      refChart
    ]
  )
  
  const chartData = useMemo<IChartistData>(
    () => ({
      labels: props.data.map((v) => t(v.monthShortText)),
      series: [props.data.map((v) => v.litersDelivered)],
    }),
    [props.data]
  )
  
  const chartOptions = useMemo<IBarChartOptions>(
    () => ({
      axisX: {
        showGrid: false,
        labelOffset: {
          y: 10
        }
      },
      axisY: {
        offset: isMobile ? 20 : 35,
        showLabel: !!props.data,
        labelInterpolationFnc: shortenNumber
      }
    }),
    [props.data]
  )
  
  const dynamicStyle = useMemo<string>(
    () => {
      const base = 'deliveries-chart'
      return base + (props.loading ? ' loading' : '')
    },
    [props.loading]
  )
  
  function shortenNumber(n: number): string {
    const Mega: number = 1_000_000
    const Kilo: number = 1_000
    if (n >= Mega) return (n / Mega).toFixed(0) + 'M'
    if (n >= Kilo) return (n / Kilo).toFixed(0) + 'k'
    return n.toString()
  }
  
  function mountChart(): void {
    const instance = new Bar(refChart.current, chartData, chartOptions)
    setBarChart(instance)
  }
  
  function unmountChart(): void {
    barChart?.detach()
    setBarChart(undefined)
  }

  useEffect(() => {
    if (isReady) {
      barChart?.update(chartData, chartOptions)
    }
  }, [isReady]);

  useEffect(() => {
    mountChart()
    return () => {
      unmountChart()
    }
  }, []);

  return (
    <div className={classNames(dynamicStyle, props.className)}>
      <div
        ref={refChart}
        className="chart w-full h-full"/>
    </div>
  )
}

export default DeliveriesChart
