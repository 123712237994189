import { useQuery } from "$lib/hooks/fetch-utillities";
import { ShipToQuickSearchDocument } from "$typings/graphql-codegen";
import { useMemo } from "react";
import useShipToQuickSearchVariables from "./useShipToQuickSearchVariables";
import { usePersistedState } from "$lib/hooks/usePersistedState";

export default function useShipToQuickSearchQuery() {
  const CacheKey = 'quickSearchShipToKey'
  const [searchValue, setSearchValue] = usePersistedState(CacheKey, '')
  const variables = useShipToQuickSearchVariables(searchValue)

  const { data, loading } = useQuery(
    ShipToQuickSearchDocument,
    variables
  )

  const mutatedData = useMemo(() => {
    const siteListData = data?.elasticSearchPages.sitelist.data
    const edges = siteListData?.edges ?? []
    if (edges.length < 1) return null
    const site = siteListData?.edges[0]
    const tankChannels = siteListData?.edges[0].tankChannels ?? []
    const tank = tankChannels[0]
    
    return {
      lastSample: tank?.lastSample ?? null,
      percentage: tank?.percentage ?? null,
      minimum: tank?.minimum ?? null,
      maximum: tank?.maximum ?? null,
      capacity: tank?.capacity ?? null,
      lastSampleTime: tank?.lastSampleTime ?? null,
      reachMinimum: tank?.tankDetails?.reachMinimum,
      alias: site?.alias,
      siteId: site?.siteId,
      totalCount: siteListData?.totalCount ?? null
    }
  }, [data, loading])

  const siteUrl = useMemo(() => {
    const siteId: string = mutatedData?.siteId ?? ''
    return siteId ? `sitedetails/${siteId}` : ''
  }, [mutatedData])

  const siteListUrl = useMemo(
    () => searchValue ? `sitelistpage?shipto=${searchValue}` : '',
    [searchValue]
  )

  const hasSearchValue = useMemo(
    () => !!searchValue && searchValue.length > 0,
    [searchValue]
  )

  const hasData = useMemo(() => {
    const siteList = data?.elasticSearchPages?.sitelist
    const n = siteList?.data?.totalCount ?? 0
    return n > 0
  }, [data])

  const showResult = useMemo(() => {
    return hasSearchValue && hasData
  }, [hasSearchValue, hasData])

  const showNoMatch = useMemo(() => {
    return !loading && !hasData && hasSearchValue
  }, [loading, hasData, hasSearchValue])

  return {
    data,
    mutatedData,
    loading,
    siteUrl,
    siteListUrl,
    showResult,
    showNoMatch,
    searchValue,
    setSearchValue
  }
}