export const SelectedBuidCacheKey = 'IDAXLifecycleWidgetSelectedBuidKey'
export const PercentOrNumberCacheKey = 'IDAXLifecycleWidgetPercentKey'
export const NumberOfTypesToShow = 4
export enum Colors {
  RED = '#C2404090',
  GREEN = '#78A22F90',
  BLUE = '#0076BC90',
  GRAY = '#CDCDCD90',
  ORANGE = '#F89828',

 
}
export const MainColors = [
  Colors.GREEN,
  Colors.RED,
  Colors.BLUE,
  Colors.GRAY,
  Colors.ORANGE,

]
export function percentReducer(sum: number, next: {percent: number}): number {
  return sum + next.percent
}
export type WidgetCircleValue = {
  color: Colors,
  type: string,
  percent: number,
  count: number,
  buidId?: string
}