import {IFilterGroup} from "$interfaces/iFilter";
import {addNotExistingDefaultFilters} from "$pages/sitelistpage-react/modules/sitelist-utils";
import {SiteSignal} from "../../../../models";
import {ShortcutsQueryVariables} from "$typings/graphql";
import {ShortcutsVisibilityRecord} from "$pages/dashboard/shortcuts/hooks/useShortcutsVisibility";

type SitesFilters = {
  onlineSitesFilters: IFilterGroup[] | null,
  offlineSitesFilters: IFilterGroup[] | null,
  parkedSitesFilters: IFilterGroup[] | null,
  virtualSitesFilters: IFilterGroup[] | null,
  activeAlarmsSites: IFilterGroup[] | null,
}

/**
 * # Create Filter Groups
 * @param field Key to filter.
 * @param type Type of filter.
 * @param value Filter value.
 * @param selected Whether it is selected or not.
 * @returns An array of structured objects used as filters in the API.
 */
function createFilterGroups(
  field: string,
  type: string,
  value: any,
  selected?: boolean
): IFilterGroup[] {
  return addNotExistingDefaultFilters([{
    exclude: false,
    field,
    type,
    filters: [{value, selected}]
  }])
}

/**
 * # Create Filter Groups Record
 * @returns A record of filter groups per site type.
 */
function createFilterGroupsRecord() {
  const online = SiteSignal.Online.toString()
  const offline = SiteSignal.Offline.toString()
  return {
    onlineSitesFilters: createFilterGroups('sitesignal', 'ref', online, true),
    offlineSitesFilters: createFilterGroups('sitesignal', 'ref', offline, true),
    parkedSitesFilters: createFilterGroups('isParked', 'boolean', true),
    virtualSitesFilters: createFilterGroups('isVirtual', 'boolean', true),
    activeAlarmsSites: createFilterGroups('hasAlarm', 'boolean', true),
  }
}

/**
 * # Create Variable Object
 * @param filters Sites filters.
 * @param visibilityRecord An object depicting visibility of site types.
 * @returns A specialized object used in conjunction with the shortcuts query.
 */
function createVariableObject(
  filters: SitesFilters,
  visibilityRecord: ShortcutsVisibilityRecord
): ShortcutsQueryVariables {
  const str = (v: any) => JSON.stringify(v)
  return {
    onlineSitesFilter: str((filters.onlineSitesFilters)),
    offlineSitesFilter: str((filters.offlineSitesFilters)),
    parkedSitesFilter: str((filters.parkedSitesFilters)),
    virtualSitesFilter: str((filters.virtualSitesFilters)),
    activeAlarmsSitesFilter: str((filters.activeAlarmsSites)),
    onlineSitesCountVisible: visibilityRecord.siteListOnlineSites,
    offlineSitesCountVisible: visibilityRecord.siteListOfflineSites,
    parkedSitesCountVisible: visibilityRecord.siteListParkedSites,
    virtualSitesCountVisible: visibilityRecord.siteListVirtualSites,
    activeAlarmsSitesCountVisible: visibilityRecord.siteListActiveAlarmsSites
  }
}

export function useShortcutsVariables(visibilityRecord: ShortcutsVisibilityRecord): ShortcutsQueryVariables {
  const filterGroups = createFilterGroupsRecord()
  return createVariableObject(filterGroups, visibilityRecord)
}
