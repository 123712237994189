import React, {FC, HTMLAttributes, useMemo, useState} from 'react'
import './tank-reach-minimum-widget.css'
import {useCaseInsensitiveTranslation} from "$lib/hooks/case-insensitive-translation";
import {useQuery} from "$lib/hooks/fetch-utillities";
import {TankReachMinimumWidgetQueryDocument} from "$typings/graphql-codegen";
import {SortDirection} from "$typings/graphql";
import {ColumnAccess} from "../../../../../config";
import {addNotExistingDefaultFilters} from "$pages/sitelistpage-react/modules/sitelist-utils";
import RenderIf from "$components/render-if/render-if";
import PlaceholderText from "$components/placeholders/placeholder-text/placeholder-text.react";
import Icon from "$components/icons/icon/icon.react";
import {CustomIcon} from "$components/icons/icon/CustomIcon";
import Button from "$components/buttons/button.react";
import {useIsMobile} from "$lib/hooks/isMobile";
import classNames from 'classnames';
import TwCard from "$components/cards/tw-card/tw-card";
import TwCardHeader from "$components/cards/tw-card/tw-card-header";
import TwCardBody from "$components/cards/tw-card/tw-card-body";
import TwCardFooter from "$components/cards/tw-card/tw-card-footer";

type Tank = {
  siteChannelId: number,
  tankDetails: {
      reachMinimumSpan: number
  }
}

type TankSite = {
  alias: string,
  siteId: string,
  tankChannels: Tank[]
}

interface TankSiteWithTankReachMinimum extends TankSite {
  tankReachingMinimum: Tank
}

const TankReachMinimumWidget: FC<HTMLAttributes<HTMLElement>> = ({className}) => {
  const [t] = useCaseInsensitiveTranslation()
  const [error, setError] = useState<string>()
  const isMobile = useIsMobile()

  // TODO: Figure out this one
  //  const MsInDay = 1000 * 60 * 60 * 24
  const Variables = {
    sortDirection: SortDirection.Asc,
    sortProperty: ColumnAccess.ReachMinimumInDays,
    filters: JSON.stringify(addNotExistingDefaultFilters([{
        field: 'tankisparked',
        type: 'boolean',
        exclude: true,
        filters: [{ 'value': true }]
      }])
    ),
    numberToShow: 4,
  }
  
  const {
    data,
    loading,
    revalidate
  } = useQuery(
    TankReachMinimumWidgetQueryDocument,
    Variables,
    undefined,
    undefined,
    (error?: string | undefined) => {
      error && setError(error)
    }
  )

  /**
   * # Get Tank With Lowest Minimum Span
   * @param a Tank A
   * @param b Tank B
   * @returns The tank having the lowest `reachMinimumSpan` value.
   */
  function getTankWithMinSpan(a: Tank, b: Tank): Tank {
    const spanA = a.tankDetails.reachMinimumSpan ?? 0
    const spanB = b.tankDetails.reachMinimumSpan ?? 0
    return spanA < spanB ? a : b
  }

  /**
   * # To Tank Site With Tank Reaching Minimum
   * @param tankSite Tank site
   * @returns Mutated tank site having a tank reaching minimum span.
   * @see TankSiteWithTankReachMinimum
   */
  function toTankSiteWithTankReachingMinimum(tankSite: TankSite): TankSiteWithTankReachMinimum {
    const tankChannels = tankSite.tankChannels ?? [] as Tank[]
    return {
      ...tankSite,
      tankReachingMinimum: tankChannels.reduce(getTankWithMinSpan)
    }
  }

  /**
   * Mutated array of tank sites
   * @see TankSiteWithTankReachMinimum
   */
  const tankSitesReachMinimum = useMemo<TankSiteWithTankReachMinimum[]>(
    () => {
      if (loading || !data) return []
      const tankSites = data?.elasticSearchPages?.sitelist?.data?.edges ?? [] as TankSite[]
      return tankSites.map(toTankSiteWithTankReachingMinimum)
    },
    [data, loading]
  )

  /**
   * # Get Reach Min Span
   * @param tankSite Tank site with tank reaching minimum.
   * @returns The tank's reach minimum span value.
   */
  function getReachMinSpan(tankSite: TankSiteWithTankReachMinimum): number {
    return tankSite?.tankReachingMinimum?.tankDetails?.reachMinimumSpan ?? 0
  }
  
  function reFetch(): void {
    revalidate && revalidate()
  }

  const getSiteChannelDetailsURL = (
    item: {siteId: string},
    tank: {siteChannelId: number}
  ) => `/sitedetails/${item.siteId}/tanks/tankDetails/${tank.siteChannelId}`
  const SiteListPageURL = `/sitelistpage?tankIsParked=false&sortProperty=${Variables.sortProperty}&sortDirection=${Variables.sortDirection}`

  return (
    <TwCard className={classNames('tank-reach-minimum-widget', className)}>
      <TwCardHeader>{t('UI_Widget_Header_Next_Reach_Minimums')}</TwCardHeader>
      <TwCardBody className="flex-col gap-4">
        <RenderIf if={loading}>
          <div className="grow flex flex-col gap-4">
            {[...Array(Variables.numberToShow).fill(null)].map((_, i: number) => (
              <div
                key={i}
                className="row">
                <div className="daybar">
                  <PlaceholderText />
                </div>
                <div className="sitename">
                  <PlaceholderText />
                </div>
              </div>
            ))}
          </div>
        </RenderIf>
        <RenderIf if={data}>
          <div className="grow flex flex-col gap-4">
            {tankSitesReachMinimum.map((tankSite, itemIdx: number) => (
              <div key={itemIdx}>
                <RenderIf if={tankSite.tankReachingMinimum}>
                  <div className="row">
                    <div className={classNames(
                      'daybar',
                      'text-align-right',
                      (getReachMinSpan(tankSite) < 1) && 'red'
                    )}>
                      { getReachMinSpan(tankSite) } days
                    </div>
                    <div className="sitename truncate">
                      <a
                        href={getSiteChannelDetailsURL(tankSite, tankSite.tankReachingMinimum)}
                        className="no-text-decoration">
                        {tankSite?.alias}
                      </a>
                    </div>
                  </div>
                </RenderIf>
              </div>
            ))}
          </div>
        </RenderIf>
        <RenderIf if={error && !loading}>
          <div className="flex column jccenter aicenter flex_1">
            <div>
              {t('UI_Dashboard_Item_ErrorLoadingData')}
            </div>
            <Button
              variant="tertiary"
              onClick={reFetch}>
              Try again
            </Button>
          </div>
        </RenderIf>
      </TwCardBody>
      <RenderIf if={!isMobile && (loading || data)}>
        <TwCardFooter className="justify-end">
          <RenderIf if={loading}>
            <PlaceholderText/>
          </RenderIf>
          <RenderIf if={data}>
            <a
              className="no-underline flex gap-2"
              href={SiteListPageURL}>
              Open in sitelist <Icon name={CustomIcon.ARROW_RIGHT} />
            </a>
          </RenderIf>
        </TwCardFooter>
      </RenderIf>
    </TwCard>
  )
}

export default TankReachMinimumWidget
