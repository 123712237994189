import React, {FC, HTMLAttributes} from "react";
import classNames from "classnames";

const TwCardBody: FC<HTMLAttributes<HTMLElement>> = (props) => {
  return (
    <div
      {...props}
      className={classNames('flex grow p-4', props.className)}>
      { props.children }
    </div>
  )
}

export default TwCardBody
