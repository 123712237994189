import React, {FC, HTMLAttributes} from "react";
import {IDashboardEditContext, useDashboardEditContext} from "../context/dashboard-edit-context";
import classNames from "classnames";
import CardFooter from "$components/cards/card-footer/card-footer.react";
import Button from "$components/buttons/button.react";

const DashboardEditFooter: FC<HTMLAttributes<HTMLElement>> = (props) => {
  const ctx: IDashboardEditContext = useDashboardEditContext()
  const className: string = classNames('showOnlyOnMobile', props.className)
  function close() {
    ctx.open.setOpen(false)
  }
  return (
    <CardFooter className={className}>
      <Button variant="primary" onClick={close}>
        Save
      </Button>
    </CardFooter>
  )
}

export default DashboardEditFooter
