import React, {FC, HTMLAttributes} from "react";
import classNames from "classnames";

const TwCardHeader: FC<HTMLAttributes<HTMLElement>> = (props) => {
  return (
    <div className={classNames('rounded-t text-xl px-4 pt-4 flex items-center', props.className)}>
      { props.children }
    </div>
  )
}

export default TwCardHeader
