import { ConditionalOperator } from "$interfaces/iFilter";
import { DeliveryNoteQuickSearchVariables } from "$typings/graphql";
import { SearchMode } from "../SearchMode";

export default function useDeliveryNoteQuickSearchVariables(
  searchValue: string,
  dateValue: string,
  searchMode: SearchMode
): DeliveryNoteQuickSearchVariables {
  if (searchMode === SearchMode.Date) {
    return {
      freeTextQuery: [],
      filters: JSON.stringify([{
        field: 'refilldate',
        type: 'date',
        exclude: false,
        filters: [{
          operator: ConditionalOperator.Equals,
          value: dateValue,
          symbol: '='
        }]
      }]),
    }
  }

  return {
    freeTextQuery: [searchValue]
  }
}