import Icon from "$components/icons/icon/icon.react";
import TextField from "$components/textbox/text-field.react";
import { useCaseInsensitiveTranslation } from "$lib/hooks/case-insensitive-translation";
import React, { FC } from 'react';
import StatelessQuickSearchWidget from "../common/stateless-quick-search-widget";
import useChannelIdQuickSearchQuery from './hooks/useChannelIdQuickSearchQuery';
import { ensureNumber } from '$lib/numberHelpers';

export interface ChannelIdQuickSearchProps {}

const SoldToQuickSearch: FC<ChannelIdQuickSearchProps> = () => {
  const [t] = useCaseInsensitiveTranslation()
  const Header = t('UI_Dashboard_Quicksearch_ChannelId')

  const {
    mutatedData,
    loading,
    showResult,
    showNoMatch,
    siteListUrl,
    searchValue,
    setSearchValue,
  } = useChannelIdQuickSearchQuery()
  
  const SearchIcon: JSX.Element = <Icon name={'fa-search'}/>

  const SearchField: JSX.Element = (
    <TextField
      value={searchValue}
      icon={SearchIcon}
      placeholder={Header}
      removeWhitespace={true}
      onChange={(e) => setSearchValue(e.target.value)} />
  )

  const toChannelTypeText = (channelTypeNum: number | string | undefined) => {
    if (!channelTypeNum)
      return '';

    switch (ensureNumber(channelTypeNum)) {
      case 0:
        return t('UI_ChannelTypes_Unknown');
      case 1:
        return t('UI_ChannelTypes_Dose');
      case 2:
        return t('UI_ChannelTypes_Flow');
      case 3:
        return t('UI_ChannelTypes_H2SGas');
      case 5:
        return t('UI_ChannelTypes_RetentionTime');
      case 6:
        return t('UI_ChannelTypes_Tank');
      case 7:
        return t('UI_ChannelTypes_Aggregation');
      case 8:
        return t('UI_ChannelTypes_Temperature');
      case 9:
        return t('UI_ChannelTypes_Battery');
      case 10:
        return t('UI_ChannelTypes_UnknownWithSum');
      case 11:
        return t('UI_ChannelTypes_AggregatedTankUsage');
      case 12:
        return t('UI_ChannelTypes_AggregatedDose');
      case 13:
        return t('UI_ChannelTypes_StockVsDoseAccuracy');
      case 14:
        return t('UI_ChannelTypes_Counter');
      case 15:
        return t('UI_ChannelTypes_DissolvedSulphides');
      case 16:
        return t('UI_ChannelTypes_Conductivity');
    }

    return t('UI_ChannelTypes_Unknown');
  } 
  
  const SearchResultComponent = (
    <a 
      className="no-underline"
      href={siteListUrl}>
      { mutatedData?.channelId &&
        <p className="truncate">         
          {mutatedData?.channelAlias} ({toChannelTypeText(mutatedData?.channelType)})
        </p>
      }
      { !mutatedData?.channelId &&
        <p className="truncate">
          No match found
        </p>
      }
      </a>
      )

      return (
      <StatelessQuickSearchWidget
      header={Header}
      searchComponent={SearchField}
      isLoading={loading}
      showResult={showResult}
      showNoMatch={showNoMatch}
      searchResultComponent={SearchResultComponent}
      dataCount={mutatedData ? 1 : 0}
      url={siteListUrl} />
  )
}

export default SoldToQuickSearch
