import { ShipToQuickSearchVariables } from "$typings/graphql";

export default function useShipToQuickSearchVariables(searchValue: string): ShipToQuickSearchVariables {
  return {
    filter: JSON.stringify([{
      field: 'tankshipto',
      type: 'string',
      exclude: false,
      partialMatch: true,
      filters: [{ value: searchValue, selected: true }],
    }])
  }
}