import React, {FC, HTMLAttributes, useMemo} from 'react'
import './new-sites-table.css'
import {NewSitesData} from "$pages/dashboard/widgets/modules/new-sites-widget/common/new-sites-data";
import RenderIf from "$components/render-if/render-if";
import PlaceholderText from "$components/placeholders/placeholder-text/placeholder-text.react";
import {useCaseInsensitiveTranslation} from "$lib/hooks/case-insensitive-translation";
import classNames from "classnames";
import TwCardFooter from "$components/cards/tw-card/tw-card-footer";

export interface NewSitesTableProps extends HTMLAttributes<HTMLElement> {
  data: NewSitesData,
  isLoading: boolean
}

const NewSitesTable: FC<NewSitesTableProps> = (props) => {
  const [t] = useCaseInsensitiveTranslation()
  
  const months = useMemo(
    () => props.data.months ?? [],
    [props.data]
  )
  
  const totalNewSites = useMemo<string>(
    () => {
      if (!props.data) return '0'
      const sum = (sum: number, curr: {count: number}) => sum + curr.count
      const n = props.data.months.reduce(sum, 0)
      return n.toLocaleString()
    },
    [props.data]
  )
  
  return (
    <div className={classNames('new-sites-table flex-col', props.className)}>
      <div className="table flex column">
        <div className="flex_1 months">
          {months.map((month, i) => (
            <div
              key={i}
              className="flex jsb month">
              <div className="cell desc">
                <RenderIf if={month}>
                <span>
                  {t(month.monthText)} {month.year}
                </span>
                </RenderIf>
                <RenderIf if={props.isLoading}>
                  <PlaceholderText/>
                </RenderIf>
              </div>
              <div className="cell number-cell">
                <RenderIf if={month}>
                <span>
                  {month.count} sites
                </span>
                </RenderIf>
                <RenderIf if={props.isLoading}>
                  <PlaceholderText/>
                </RenderIf>
              </div>
            </div>
          ))}
        </div>
      </div>
      <TwCardFooter className="">
        <div className="cell desc">
          <RenderIf if={months}>
            <span>
              Total new sites:
            </span>
          </RenderIf>
          <RenderIf if={props.isLoading}>
            <PlaceholderText/>
          </RenderIf>
        </div>
        <div className="cell number-cell">
          <RenderIf if={months}>
            <span>
              {totalNewSites} sites
            </span>
          </RenderIf>
          <RenderIf if={props.isLoading}>
            <PlaceholderText/>
          </RenderIf>
        </div>
      </TwCardFooter>
    </div>
  )
}

export default NewSitesTable
