import CollapsableSection from "$components/collapsable-section/collapsable-section.react";
import RenderIf from "$components/render-if/render-if";
import { useCaseInsensitiveTranslation } from "$lib/hooks/case-insensitive-translation";
import CustomerRefQuickSearch from "$pages/dashboard/quicksearch/customerref-quicksearch/customer-ref-quick-search";
import DeliveryNoteQuickSearch from "$pages/dashboard/quicksearch/deliverynote-quicksearch/delivery-note-quick-search";
import FreetextQuickSearch from "$pages/dashboard/quicksearch/freetext-quicksearch/freetext-quick-search";
import ShipToQuickSearch from "$pages/dashboard/quicksearch/shipto-quicksearch/ship-to-quick-search";
import SoldToQuickSearch from "$pages/dashboard/quicksearch/soldto-quicksearch/sold-to-quick-search";
import ChannelIdQuickSearch from "$pages/dashboard/quicksearch/channelid-quicksearch/channelid-quick-search";
import React, { FC } from 'react';
import { DashboardCollapsables } from '../DashboardCollapsables';
import { useQuickSearchVisibility } from "./hooks/useQuickSearchVisibility";
import './quicksearch.css';

export interface QuickSearchProps {}

const QuickSearch: FC<QuickSearchProps> = () => {
  const [t] = useCaseInsensitiveTranslation()
  const visibility = useQuickSearchVisibility()
  const someAreVisible = Object.values(visibility).some((v: boolean) => v === true)
  
  return (
    <RenderIf if={someAreVisible}>
      <CollapsableSection
        header={t('UI_Dashboard_Quicksearch_Header')}
        persistState={true}
        name={'dashboard-' + DashboardCollapsables.QUICK_SEARCH}>
        <div className="quick-search-wrapper">
          <RenderIf if={visibility.customerRef}>
            <CustomerRefQuickSearch />
          </RenderIf>
          <RenderIf if={visibility.shipTo}>
            <ShipToQuickSearch />
          </RenderIf>
          <RenderIf if={visibility.soldTo}>
            <SoldToQuickSearch />
          </RenderIf>
          <RenderIf if={visibility.freetext}>
            <FreetextQuickSearch />
          </RenderIf>
          <RenderIf if={visibility.deliveryNote}>
            <DeliveryNoteQuickSearch />
          </RenderIf>
          <RenderIf if={visibility.channelId}>
            <ChannelIdQuickSearch /> 
          </RenderIf>
        </div>
      </CollapsableSection>
    </RenderIf>
  )
}

export default QuickSearch
