import {DashboardItemNameEnum} from "$pages/dashboard/dashboard-edit/DashboardItemNameEnum";
import useItemAccessRecord from "$pages/dashboard/hooks/useItemAccessRecord";
import usePersistedItemVisibility from "$pages/dashboard/hooks/usePersistedItemVisibility";

export interface ShortcutsVisibilityRecord {
  siteList: boolean
  siteListMap: boolean
  siteListOnlineSites: boolean
  siteListOfflineSites: boolean
  siteListParkedSites: boolean
  siteListVirtualSites: boolean
  siteListActiveAlarmsSites: boolean
  liveMap: boolean
  releaseNotes: boolean
}

export function useShortcutsVisibility(): ShortcutsVisibilityRecord {
  const { items } = usePersistedItemVisibility()
  const accessRecord = useItemAccessRecord().Shortcuts

  const hasAccess = (access: boolean, item?: DashboardItemNameEnum): boolean => {
    if (item !== undefined) return access && items.includes(item)
    return access
  }
  
  return {
    siteList: hasAccess(accessRecord.SiteList, DashboardItemNameEnum.Sitelist),
    siteListMap: hasAccess(accessRecord.SiteListMap, DashboardItemNameEnum.SitelistMap),
    siteListOnlineSites: hasAccess(accessRecord.SiteListOnline, DashboardItemNameEnum.SitelistOnlineSites),
    siteListOfflineSites: hasAccess(accessRecord.SiteListOffline, DashboardItemNameEnum.SitelistOfflineSites),
    siteListParkedSites: hasAccess(accessRecord.SiteListParked, DashboardItemNameEnum.SitelistParkedSites),
    siteListVirtualSites: hasAccess(accessRecord.SiteListVirtual, DashboardItemNameEnum.SitelistVirtualSites),
    siteListActiveAlarmsSites: hasAccess(accessRecord.SiteListActiveAlarms, DashboardItemNameEnum.SitelistActiveAlarms),
    liveMap: hasAccess(accessRecord.LiveMap, DashboardItemNameEnum.LiveMap),
    releaseNotes: hasAccess(accessRecord.ReleaseNotes, DashboardItemNameEnum.Releasenotes),
  }
}
