import { SoldToQuickSearchVariables } from "$typings/graphql";
import { QuickSearchFilterInterface } from "../../interfaces/quick-search-filter-interface";

export default function useSoldToQuickSearchVariables(searchValue: string): SoldToQuickSearchVariables {
  const filter: QuickSearchFilterInterface = {
    field: 'soldto',
    type: 'string',
    exclude: false,
    partialMatch: true,
    filters: [{
      value: searchValue,
      selected: true
    }],
  }

  return {
    filter: JSON.stringify([filter])
  }
}