import React, {FC, HTMLAttributes, useMemo} from 'react'
import './new-sites-widget.css'
import Icon from "$components/icons/icon/icon.react";
import {CustomIcon} from "$components/icons/icon/CustomIcon";
import {useCaseInsensitiveTranslation} from "$lib/hooks/case-insensitive-translation";
import {useQuery} from "$lib/hooks/fetch-utillities";
import {NewSitesWidgetQueryDocument, NewSitesWidgetQueryQuery} from "$typings/graphql-codegen";
import {NewSitesData} from "$pages/dashboard/widgets/modules/new-sites-widget/common/new-sites-data";
import {months, shortMonths} from "$lib/dateHelpers";
import RenderIf from "$components/render-if/render-if";
import WidgetScroller from "$pages/dashboard/widgets/modules/scroller/widget-scroller";
import SlideWrapper from "$pages/dashboard/widgets/modules/new-sites-widget/slide-wrapper/slide-wrapper";
import {usePersistedBooleanState} from "$lib/hooks/usePersistedState";
import TwCard from "$components/cards/tw-card/tw-card";
import TwCardHeader from "$components/cards/tw-card/tw-card-header";
import TwCardBody from "$components/cards/tw-card/tw-card-body";
import classNames from "classnames";

type NewSitesWidgetData = NewSitesWidgetQueryQuery['newSitesWidget'][0]

const NewSitesWidget: FC<HTMLAttributes<HTMLElement>> = (props) => {
  const CacheKey = 'NewSitesWidgetShowTable'
  const [t] = useCaseInsensitiveTranslation()
  const [showTable, setShowTable] = usePersistedBooleanState(CacheKey, false)
  
  const {
    data,
    loading
  } = useQuery(
    NewSitesWidgetQueryDocument
  )

  const distinctBuids: string[] = useMemo(
    () => {
      const selectBuids = (v: NewSitesWidgetData) => v.buids
      const selectName = (v: {name: string}) => v.name
      const whereUnique = (v: any, i: number, self: any[]) => self?.indexOf(v) === i

      const buids = data?.newSitesWidget.flatMap(selectBuids)
      const buidNames = buids?.map(selectName)?.sort()
      const uniqueBuidNames = buidNames?.filter(whereUnique)?.sort()

      uniqueBuidNames?.unshift('All')

      return uniqueBuidNames ?? []
    },
    [data]
  )

  const mappedData: NewSitesData[] | undefined = useMemo(
    () => {
      if (!data) return undefined
      return distinctBuids.map((buid: string, i: number) => ({
        buid,
        index: i,
        months: Array(13).fill(null).map((_, j: number) => {
          const item = data.newSitesWidget[j]
          const dateArray = item.month.split('-')
          const month: string = dateArray[1]
          const year: string = dateArray[0]
          const whereNameMatchBuid = (v: {name: string}) => v.name === buid
          let count: number = item.buids.find(whereNameMatchBuid)?.count ?? 0
          if (buid === 'All') {
            count = item.count
          }

          return {
            monthShortText: shortMonths[month],
            count,
            monthText: months[month],
            year,
          }
        })
      }))
    },
    [data]
  )
  
  const HeaderIcon = () => {
    const name: CustomIcon = showTable ? CustomIcon.BAR_CHART_2 : CustomIcon.FILE_TEXT
    return <Icon name={name} onClick={toggleTableOrChartView} />
  }

  function toggleTableOrChartView() {
    setShowTable(!showTable)
  }

  return (
    <TwCard className={classNames('new-sites-widget', props.className)}>
      <TwCardHeader className="justify-between">
        <div>{t('UI_Widget_Header_New_Sites')}</div>
        <HeaderIcon />
      </TwCardHeader>
      <TwCardBody
        className={classNames(
          'flex-col gap-4 rounded-b overflow-hidden',
          showTable && 'justify-end'
        )}
        style={{padding: 0}}>
        <RenderIf if={mappedData}>
          <WidgetScroller
            rememberSlide={true}
            className="h-full"
            name="new_sites_widget">
            {mappedData?.map((item, i) => (
              <SlideWrapper
                key={item.buid + i}
                data={item}
                isLoading={loading}
                showTable={showTable} />
            ))}
          </WidgetScroller>
        </RenderIf>
      </TwCardBody>
    </TwCard>
  )
}

export default NewSitesWidget
