import React, {FC, HTMLAttributes, useMemo} from 'react'
import './deliveries-table.css'
import {
  DeliveriesBuidData
} from "$pages/dashboard/widgets/modules/deliveries-year-to-date-widget/deliveries-data";
import {useCaseInsensitiveTranslation} from "$lib/hooks/case-insensitive-translation";
import RenderIf from "$components/render-if/render-if";
import PlaceholderText from "$components/placeholders/placeholder-text/placeholder-text.react";
import CardFooter from "$components/cards/card-footer/card-footer.react";

export interface DeliveriesTableProps extends HTMLAttributes<HTMLElement> {
  data: DeliveriesBuidData[],
  loading: boolean
}

const DeliveriesTable: FC<DeliveriesTableProps> = (props) => {
  const [t] = useCaseInsensitiveTranslation()
  
  const totalDelivered = useMemo<string>(
    () => {
      if (props.data) {
        return props.data.reduce((cur: number, delivery: DeliveriesBuidData) => {
          return cur + delivery.litersDelivered
        }, 0).toLocaleString()
      }
      return ''
    },
    [props.data]
  )
  
  const dynamicClassName = useMemo<string>(
    () => {
      const base = 'deliveries-table ' + (props.className ?? '')
      return base + (props.loading ? ' loading' : '')
    },
    [props.loading]
  )
  
  return (
    <div className={dynamicClassName}>
      <div className="table flex column">
        <div className="flex_1 months">
          {props.data.map((v: DeliveriesBuidData, i: number) => (
            <div
              key={i}
              className="flex jsb month">
              <div className="cell desc">
                <RenderIf if={!props.loading && v}>
                  <span>
                    { t(v?.monthText) } { v?.year ?? '' }
                  </span>
                </RenderIf>
                <RenderIf if={props.loading}>
                  <PlaceholderText />
                </RenderIf>
              </div>
              <div className="cell number-cell">
                <RenderIf if={!props.loading && v.monthText}>
                  <span>{ v.litersDeliveredFormatted } ltr</span>
                </RenderIf>
                <RenderIf if={props.loading}>
                  <PlaceholderText />
                </RenderIf>
              </div>
            </div>
          ))}
        </div>

        <CardFooter className="flex jsb total-footer month">
          <div className="cell desc">
            <RenderIf if={totalDelivered}>
              <span>Sum:</span>
            </RenderIf>
            <RenderIf if={props.loading}>
              <PlaceholderText />
            </RenderIf>
          </div>
          <div className="cell number-cell">
            <RenderIf if={totalDelivered}>
              <span>
                {totalDelivered} ltr
              </span>
            </RenderIf>
            <RenderIf if={props.loading}>
              <PlaceholderText />
            </RenderIf>
          </div>
        </CardFooter>
      </div>
    </div>
  )
}

export default DeliveriesTable
