import React, {FC, HTMLAttributes, useEffect, useState} from 'react'
import './controller-receiving-places-widget.css'
import {useCaseInsensitiveTranslation} from "$lib/hooks/case-insensitive-translation";
import {useQuery} from "$lib/hooks/fetch-utillities";
import {DeviceSimMarriageWidgetDocument, DeviceSimMarriageWidgetQuery} from "$typings/graphql-codegen";
import {DeviceSimMarriageWidget_deviceSimMarriageWidget} from "$typings/graphql";
import {orderByPredicate} from "$lib/sorting";
import Icon from "$components/icons/icon/icon.react";
import RenderIf from "$components/render-if/render-if";
import PlaceholderText from "$components/placeholders/placeholder-text/placeholder-text.react";
import WidgetScroller from "$pages/dashboard/widgets/modules/scroller/widget-scroller";
import {round} from "$lib/helpers";
import TwCard from "$components/cards/tw-card/tw-card";
import TwCardHeader from "$components/cards/tw-card/tw-card-header";
import TwCardBody from "$components/cards/tw-card/tw-card-body";
import classNames from "classnames";
import {usePersistedBooleanState} from "$lib/hooks/usePersistedState";

type Controller = DeviceSimMarriageWidget_deviceSimMarriageWidget

interface IDataSourceItem {
  color: string;
  type: string;
  percent: number;
  count: number;
}

type SortedItems = {
  items: IDataSourceItem[],
  ida11Items: IDataSourceItem[],
  ida211Items: IDataSourceItem[],
}

type QueryResult = DeviceSimMarriageWidgetQuery

const ControllerReceivingPlacesWidget: FC<HTMLAttributes<HTMLElement>> = (props) => {
  const NumOfVisibleTypes: number = 4
  const MainColors: string[] = [
    '#78A22F90', // green
    '#C2404090', //red
    '#0076BC90' //blue
  ]
  const RestColor: string = '#CDCDCD90'; // grey color used for the rest
  const CacheKey = 'ControllerReceivingPlacesWidgetPercent'
  const countSelector = (v: { count: number }) => v.count
  const countReducer = (sum: number, value: { count: number }) => sum + value.count

  const [t] = useCaseInsensitiveTranslation()
  const {data, loading} = useQuery(DeviceSimMarriageWidgetDocument)
  const [showPercent, setShowPercent] = usePersistedBooleanState(CacheKey, true)
  const [sortedItems, setSortedItems] = useState<SortedItems>({
    items: [],
    ida11Items: [],
    ida211Items: []
  })
  
  useEffect(() => {
    if (data) {
      setSortedItems({
        items: sortItems(data),
        ida11Items: sortIda11Items(data),
        ida211Items: sortIda211Items(data)
      })
    }
  }, [data])
  
  /**
   * # Mutate Controllers
   * @param controllers Array of controllers
   * @param partitionSize Number of visible controllers
   * @param filter Filter callback function used with Array.filter
   * @param sorter Sorter callback function used with orderByPredicate
   * @param sortDirection Sorting direction (asc/desc)
   * @return Array of mutated controllers
   */
  function mutateControllers(
    controllers: Controller[],
    partitionSize: number,
    filter?: (controller: Controller) => boolean,
    sorter?: (controller: Controller) => any,
    sortDirection?: 'asc' | 'desc'
  ): IDataSourceItem[] {
    const noData = controllers?.length < 0
    if (noData) return []

    let mutControllers: Controller[] = controllers

    // Filter controllers
    if (filter) {
      mutControllers = controllers.filter(filter)
    }

    // Sort controllers
    if (sorter) {
      const dir = sortDirection ?? 'desc'
      mutControllers = orderByPredicate<Controller>(mutControllers, sorter, dir)
    }

    // Partition controllers:
    const pivot = partitionSize - 1
    const visibleRange: number[] = [0, pivot]
    const overflowRange: number[] = [pivot]
    const visibleControllers: Controller[] = mutControllers.slice(...visibleRange)
    const overflowControllers: Controller[] = mutControllers.slice(...overflowRange)

    // Aggregate count:
    const totalCountSum = mutControllers.reduce(countReducer, 0)
    const overflowControllersCountSum: number = overflowControllers.reduce(countReducer, 0)

    const visibleItems: IDataSourceItem[] = visibleControllers.map((controller, i) => ({
      color: MainColors[i],
      type: controller.buid,
      percent: (controller.count / totalCountSum) * 100,
      count: controller.count
    }))

    const overflowItem: IDataSourceItem = {
      color: RestColor,
      type: 'Other',
      percent: (overflowControllersCountSum / totalCountSum) * 100,
      count: overflowControllersCountSum
    }

    const overflowRelevant: boolean = overflowControllersCountSum > 0
    if (overflowRelevant) {
      visibleItems.push(overflowItem)
    }

    return visibleItems
  }

  /**
   * # Sort items
   * Returns an array of sorted items by count in descending order.
   */
  function sortItems(o: QueryResult): IDataSourceItem[] {
    const controllers: Controller[] = groupDeviceSimMarriageWidget(o.deviceSimMarriageWidget)
    return mutateControllers(
      controllers,
      NumOfVisibleTypes,
      undefined,
      countSelector,
      'desc'
    )
  }

  /**
   * # Sort IDA 11 Items
   * Returns an array of sorted IDA 11 items by count in descending order.
   */
  function sortIda11Items(o: QueryResult) {
    const whereIda11 = (v: { deviceType: string }) => v.deviceType.toLowerCase() === 'ida-11'
    return mutateControllers(
      o.deviceSimMarriageWidget,
      NumOfVisibleTypes,
      whereIda11,
      countSelector,
      'desc'
    )
  }

  /**
   * Sort IDA 211 Items
   * Returns as array of sorted IDA 211 items by count in descending order
   */
  function sortIda211Items(o: QueryResult) {
    const whereIda211 = (c: Controller) => c.deviceType.toLowerCase() === 'ida-211'
    return mutateControllers(
      o.deviceSimMarriageWidget,
      NumOfVisibleTypes,
      whereIda211,
      countSelector,
      'desc'
    )
  }

  function groupDeviceSimMarriageWidget(
    items: DeviceSimMarriageWidget_deviceSimMarriageWidget[]
  ) {
    const groupedItems: DeviceSimMarriageWidget_deviceSimMarriageWidget[] = [];

    items.forEach(item => {
      const buidItems = items.filter(i => i.buid === item.buid);
      let sum = 0;
      buidItems.forEach(t => (sum += t.count));
      if (!groupedItems.some(g => g.buid === item.buid)) {
        groupedItems.push({
          buid: item.buid,
          count: sum,
          deviceType: item.deviceType
        });
      }
    });

    return groupedItems;
  }

  function toggleNumberPercent(): void {
    setShowPercent(!showPercent)
  }

  function getStrokeOffset(index: number, items: IDataSourceItem[]): number {
    const slicedItems = items.slice(0, index)
    const percentReducer = (a: number, b: IDataSourceItem) => a + b.percent
    const aggregatedPercent = slicedItems.reduce(percentReducer, 0)
    return 100 - aggregatedPercent + 25
  }

  const Circle = (
    props: {
      item: IDataSourceItem,
      items: IDataSourceItem[],
      idx: number
    }
  ) => {
    const percent = props.item.percent
    return (
      <circle
        className="donut-slice"
        strokeDasharray={`${percent} ${100 - percent}`}
        strokeDashoffset={getStrokeOffset(props.idx, props.items)}
        stroke={props.item.color}
        cx="20"
        cy="20"
        r="16" />
    )
  }
  
  const ActionIcon = () => {
    return (
      <Icon
        name={showPercent ? 'fa-hashtag' : 'fa-percent'}
        onClick={toggleNumberPercent} />
    )
  }

  return (
    <TwCard className={classNames('controller-receiving-places-widget', props.className)}>
      <TwCardHeader className="justify-between">
        <div>{t('UI_Dashboard_Item_Controllers')}</div>
        <ActionIcon />
      </TwCardHeader>
      <TwCardBody className="flex-col gap-4">
        <RenderIf if={loading}>
          <div className="content-wrapper flex row">
            <div className="flex flex_1 datawrapper">
              <div className="chart"/>
              <div
                className="typewrapper flex column jcaround">
                {(Array(NumOfVisibleTypes).fill(null)).map((_, i: number) => (
                  <div
                    key={i}
                    className="flex jsb row">
                    <div
                      className="circle"
                      style={{
                        background: '#efefef',
                        border: '1px solid #efefef'
                      }} />
                    <div className="sourcetype">
                      <PlaceholderText/>
                    </div>
                    <div className="percentage">
                      <PlaceholderText/>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </RenderIf>
        <RenderIf if={data}>
          <WidgetScroller
            rememberSlide={true}
            className="h-full"
            name="controllers-recieving-places-widget">
            <div
              className="flex flex_1 datawrapper"
              title="All">
              <svg
                className="chart"
                viewBox="0 0 40 40">
                {sortedItems.items.map((item: IDataSourceItem, i: number, items: IDataSourceItem[]) => (
                  <Circle
                    key={i}
                    item={item}
                    items={items}
                    idx={i} />
                ))}
              </svg>
              <div className="typewrapper flex column jcaround">
                {sortedItems.items.map((item: IDataSourceItem, i: number) => (
                  <div
                    key={i}
                    className="flex jsb row">
                    <div
                      className="circle"
                      style={{
                        background: item.color,
                        border: '1px solid ' + item.color
                      }} />
                    <div className="sourcetype">{ item.type }</div>
                    <RenderIf if={showPercent}>
                      <div className="percentage">{ round(item.percent) } %</div>
                    </RenderIf>
                    <RenderIf if={!showPercent}>
                      <div className="percentage">{ item.count }</div>
                    </RenderIf>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex_1 datawrapper" title="IDA11's">
              <svg className="chart" viewBox="0 0 40 40">
                {sortedItems.ida11Items.map((item: IDataSourceItem, i: number, items: IDataSourceItem[]) => (
                  <Circle
                    key={i}
                    item={item}
                    items={items}
                    idx={i} />
                ))}
              </svg>
              <div className="typewrapper flex column jcaround">
                {sortedItems.ida11Items.map((item: IDataSourceItem, i: number) => (
                  <div
                    key={i}
                    className="flex jsb row">
                    <div
                      className="circle"
                      style={{
                        background: item.color,
                        border: '1px solid ' + item.color
                      }}/>
                    <div className="sourcetype">{item.type}</div>
                    <RenderIf if={showPercent}>
                      <div className="percentage">{ round(item.percent) } %</div>
                    </RenderIf>
                    <RenderIf if={!showPercent}>
                      <div className="percentage">{item.count}</div>
                    </RenderIf>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex_1 datawrapper" title="IDA211's">
              <svg className="chart" viewBox="0 0 40 40">
                {sortedItems.ida211Items.map((item: IDataSourceItem, i: number, items: IDataSourceItem[]) => (
                  <Circle
                    key={i}
                    item={item}
                    items={items}
                    idx={i} />
                ))}
              </svg>
              <div className="typewrapper flex column jcaround">
                {sortedItems.ida211Items.map((item: IDataSourceItem, i: number) => (
                  <div
                    key={i}
                    className="flex jsb row">
                    <div className="circle" style={{
                      background: item.color,
                      border: '1px solid ' + item.color
                    }}/>
                    <div className="sourcetype">{item.type}</div>
                    <RenderIf if={showPercent}>
                      <div className="percentage">{ round(item.percent) } %</div>
                    </RenderIf>
                    <RenderIf if={!showPercent}>
                      <div className="percentage">{item.count}</div>
                    </RenderIf>
                  </div>
                ))}
              </div>
            </div>
          </WidgetScroller>
        </RenderIf>
      </TwCardBody>
    </TwCard>
  )
}

export default ControllerReceivingPlacesWidget
