import Button from "$components/buttons/button.react";
import TwCard from "$components/cards/tw-card/tw-card";
import TwCardBody from "$components/cards/tw-card/tw-card-body";
import TwCardHeader from "$components/cards/tw-card/tw-card-header";
import CollapsableSection from "$components/collapsable-section/collapsable-section.react";
import Icon from "$components/icons/icon/icon.react";
import LoadingBars from "$components/loading-bars/loading-bars.react";
import RenderIf from "$components/render-if/render-if";
import RequireConfirmation from "$components/require-confirmation/require-confirmation.react";
import SiteCard from "$components/sitecard-react/site-card";
import { useCaseInsensitiveTranslation } from "$lib/hooks/case-insensitive-translation";
import { useQuery } from "$lib/hooks/fetch-utillities";
import { SiteCardFragment } from "$typings/graphql";
import { PinnedSitesQueryDocument } from "$typings/graphql-codegen";
import React, { FC, HTMLAttributes, useMemo, useState } from 'react';
import requests from "../../../config/requests";
import { DashboardCollapsables } from '../DashboardCollapsables';
import usePinnedSitesVisibility from './hooks/usePinnedSitesVisibility';
import './pinnedsites.css';

export interface PinnedSitesProps extends HTMLAttributes<HTMLElement> {}

const PinnedSites: FC<PinnedSitesProps> = (_props) => {
  const [t] = useCaseInsensitiveTranslation()
  const [showUnpinConfirmation, setShowUnpinConfirmation] = useState(false)
  const visibility = usePinnedSitesVisibility()
  
  const {
    data,
    loading,
    revalidate
  } = useQuery(PinnedSitesQueryDocument)
  
  function reFetchData(): void {
    revalidate && revalidate()
  }
  
  // Unpin all sites if there are pinned sites
  function actionCallback(): void {
    const nPinnedSites: number = data?.pinnedSites?.length ?? 0
    if (nPinnedSites > 0) {
      setShowUnpinConfirmation(true)
    }
  }
  
  // TODO: Replace with tanstack
  function api(
    method: 'get' | 'post' | 'delete' | 'put',
    url: URL | RequestInfo,
    body?: string
  ): Promise<Response> {
    const headers: Record<string, string> = { 'X-CSRF': '1' }; 
    return fetch(url, { headers, method, body })
  }
  
  async function pinAllSites() {
    const req = requests.pinAllSites()
    const res = await api('post', req)
    reFetchData()
    if (res.status === 200) {
      return await res.json()
    }
  }

  async function unpinAllSites() {
    const req = requests.unpinAllSites()
    const res = await api('delete', req)
    reFetchData()
    if (res.status === 200) {
      return await res.json()
    }
  }
  
  async function dismissDashboardWelcome() {
    const req = requests.updateSettingDismissDashboardWelcome()
    const res = await api('post', req)
    reFetchData()
    if (res.status === 200) {
      await res.json()
    }
  }
  
  const hasPinnedSites: boolean = useMemo<boolean>(
    () => {
      const pinnedSites = data?.pinnedSites ?? []
      return pinnedSites.length > 0
    },
    [data, loading]
  )
  
  const hideDashboardWelcome: boolean = useMemo(
    () => !data?.currentUser.userSettings?.hideDashboardWelcome ?? false,
    [data, loading]
  )
  
  const totalSiteCountLessOrEqualsTo50: boolean = useMemo(
    () => {
      const nSiteListItems: number = data?.totalSiteCount.sitelist.data.totalCount ?? 0
      return nSiteListItems <= 50
    },
    [data, loading]
  )
  
  const displayNoPinnedSitesCard: boolean = useMemo(
    () => (!hasPinnedSites) && hideDashboardWelcome,
    [hasPinnedSites, hideDashboardWelcome]
  )
  
  const displayWelcomeCard: boolean = useMemo(
    () => !hasPinnedSites && !hideDashboardWelcome,
    [hasPinnedSites, hideDashboardWelcome]
  )
  
  function onUnpinConfirmation(): void {
    unpinAllSites()
    setShowUnpinConfirmation(false)
  }
  
  function onUnpinCancellation(): void {
    setShowUnpinConfirmation(false)
  }
  
  const WelcomeCard: FC<HTMLAttributes<HTMLElement>> = () => (
    <TwCard style={{maxWidth: '400px'}}>
      <TwCardHeader>
        { t('UI_Dashboard_NoPinnedSites_Header') }
      </TwCardHeader>
      <TwCardBody>
        <p className="flex gap-2">
          { t('UI_Dashboard_NoPinnedSites') }
          <Icon name="fa-bookmark-o" />
        </p>
      </TwCardBody>
      <TwCardBody>
        <p className="fav-sites-no-pinned-sites">
          { t('UI_Dashboard_NoPinnedSites_Go_To_Sitelist') }
          &nbsp;
          <a href="/sitelistpage">
            { t('UI_Titles_SiteList') }
          </a>
        </p>
      </TwCardBody>
      <TwCardBody className="action-buttons flex-col gap-2">
        <RenderIf if={totalSiteCountLessOrEqualsTo50}>
          <Button
            variant="primary"
            onClick={pinAllSites}>
            { t('UI_Dashboard_NoPinnedSites_PinAllSites') }
          </Button>
        </RenderIf>
        <Button
          variant="primary"
          onClick={dismissDashboardWelcome}>
          { t('UI_Dashboard_NoPinnedSites_Accept') }
        </Button>
      </TwCardBody>
    </TwCard>
  )
  
  const NoPinnedSitesCard: FC<HTMLAttributes<HTMLElement>> = () => (
    <TwCard className="pinned-sites" style={{maxWidth: '400px'}}>
      <TwCardHeader>
        { t('UI_Dashboard_PinnedSites_Header') }
      </TwCardHeader>
      <TwCardBody>
        <p className="fav-sites-no-pinned-sites">
          { t('UI_Dashboard_NoPinnedSites_Go_To_Sitelist') }
          &nbsp;
          <a href="/sitelistpage">
            { t('UI_Titles_SiteList') }
          </a>
        </p>
      </TwCardBody>
    </TwCard>
  )

  const PinnedSitesGrid: FC<HTMLAttributes<HTMLElement>> = (props) => (
    <div className="pinned-sites-grid">
      { props.children }
    </div>
  )
  
  const SiteCards: FC<HTMLAttributes<HTMLElement>> = () => (
    <>
      {(data?.pinnedSites ?? []).map((site: SiteCardFragment, i) => (
        <SiteCard
          key={i}
          site={site}
          fromGraphQl={true} />
      ))}
    </>
  )
  
  const actionIcon = useMemo(
    () => hasPinnedSites ? 'fa-trash' : '',
    [hasPinnedSites]
  )
  
  const action = useMemo(
    () => hasPinnedSites ? actionCallback : () => void 0,
    [hasPinnedSites]
  )

  return (
    <RenderIf if={visibility.pinnedSites}>
      <CollapsableSection
        header={t('UI_Dashboard_PinnedSites_Header')}
        persistState={true}
        name={'dashboard-' + DashboardCollapsables.PINNED_SITES}
        actionIcon={actionIcon}
        action={action}>
        <div className="pinned-sites">
          <div className="pinned-sites-content">
            <RenderIf if={loading || !data?.pinnedSites}>
              <LoadingBars/>
            </RenderIf>
            <RenderIf if={!loading && data?.pinnedSites}>
              <RenderIf if={displayWelcomeCard || displayNoPinnedSitesCard}>
                <PinnedSitesGrid>
                  <RenderIf if={displayWelcomeCard}>
                    <WelcomeCard />
                  </RenderIf>
                  <RenderIf if={displayNoPinnedSitesCard}>
                    <NoPinnedSitesCard />
                  </RenderIf>
                </PinnedSitesGrid>
              </RenderIf>
              <PinnedSitesGrid>
                <SiteCards />
              </PinnedSitesGrid>
            </RenderIf>
          </div>
        </div>
      </CollapsableSection>
      <RenderIf if={showUnpinConfirmation}>
        <RequireConfirmation
          onConfirm={onUnpinConfirmation}
          onCancel={onUnpinCancellation}
          entityName={t('UI_Dashboard_PinnedSites_Delete_Suffix')} />
      </RenderIf>
    </RenderIf>
  )
}

export default PinnedSites
