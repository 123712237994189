import React, {FC, HTMLAttributes, useMemo, useState} from 'react'
import './deliveries-last-widget.css'
import {useCaseInsensitiveTranslation} from "$lib/hooks/case-insensitive-translation";
import RenderIf from "$components/render-if/render-if";
import {useQuery} from "$lib/hooks/fetch-utillities";
import {DeliveriesLastWidgetQueryDocument} from "$typings/graphql-codegen";
import {getUserFeatures} from "../../../../../config/sessionService";
import {useIsMobile} from "$lib/hooks/isMobile";
import PlaceholderText from "$components/placeholders/placeholder-text/placeholder-text.react";
import Button from "$components/buttons/button.react";
import {formatDate} from "$lib/dateHelpers";
import classNames from "classnames";
import TwCard from "$components/cards/tw-card/tw-card";
import TwCardHeader from "$components/cards/tw-card/tw-card-header";
import TwCardBody from "$components/cards/tw-card/tw-card-body";

const DeliveriesLastWidget: FC<HTMLAttributes<HTMLElement>> = ({className}) => {
  const N = 6
  const [t] = useCaseInsensitiveTranslation()
  const features = getUserFeatures()
  const isMobile = useIsMobile()
  const [error, setError] = useState<string>()
  
  const {
    data,
    loading,
    revalidate
  } = useQuery(
    DeliveriesLastWidgetQueryDocument,
    undefined,
    undefined,
    undefined,
    (e?: string) => setError(e)
  )
  
  const hasAccess = useMemo<boolean>(
    () => features.deliveryNoteManager.read && !isMobile,
    [features, isMobile]
  )
  
  const dynamicStyle: string = useMemo<string>(
    (): string => {
      const base: string = 'number-item no-text-decoration'
      return hasAccess ? base : `${base} disabled`
    },
    [hasAccess]
  )
  
  function getDeliveryNoteManagerURL(id?: string): string {
    if (!id) return ''
    const url = '/deliverynotemanager?date=' + id
    return hasAccess ? url : ''
  }
  
  function getTitleText(nDelivered: number): string {
    if (!nDelivered) return t('UI_Dashboard_Deliveries_NoConfirmed')
    return t('UI_Dashboard_Deliveries_LitersDelivered', { count: nDelivered })
  }
  
  function reFetch() {
    revalidate && revalidate()
  }
  
  return (
    <TwCard className={classNames('deliveries-last-widget', className)}>
      <TwCardHeader>{t('UI_Widget_Header_Last_Deliveries')}</TwCardHeader>
      <TwCardBody className="flex-col gap-4 overflow-auto">
        <RenderIf if={data}>
          <div className="numbers-wrapper">
            {data?.deliveryNoteStatistics.map((stat, i) => (
              <a
                key={i}
                href={getDeliveryNoteManagerURL(stat.key)}
                className={dynamicStyle}
                title={getTitleText(stat.amountDelivered)}>
                <div className="deliveries">
                  { stat.deliveries } {t('UI_Widget_Deliveries_Deliveries')}
                </div>
                <div className="date">
                  { formatDate(stat.key, false, '.') }
                </div>
              </a>
            ))}
          </div>
        </RenderIf>
        <RenderIf if={loading}>
          <div className="numbers-wrapper flex_1">
            {([...Array(N).fill(null)]).map((_, i: number) => (
              <div
                key={i}
                className="number-item no-text-decoration">
                <div className="deliveries">
                  <PlaceholderText />
                </div>
                <div className="date">
                  <PlaceholderText />
                </div>
              </div>
            ))}
          </div>
        </RenderIf>
        <RenderIf if={error && !loading}>
          <div className="flex column jccenter aicenter flex_1">
            <div>
              {t('UI_Dashboard_Item_ErrorLoadingData')}
            </div>
            <Button
              variant="tertiary"
              onClick={reFetch}>
              Try again
            </Button>
          </div>
        </RenderIf>
      </TwCardBody>
    </TwCard>
  )
}

export default DeliveriesLastWidget
