import {useCaseInsensitiveTranslation} from "$lib/hooks/case-insensitive-translation";
import {useQuery} from "$lib/hooks/fetch-utillities";
import {UserStatisticsDashboardWidgetQueryDocument} from "$typings/graphql-codegen";
import {
  Button,
  Skeleton,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import React, {FC, HTMLAttributes, useMemo} from "react";
import moment from "moment";
import {useIsMobile} from "$lib/hooks/isMobile";
import classNames from "classnames";
import TwCard from "$components/cards/tw-card/tw-card";
import TwCardBody from "$components/cards/tw-card/tw-card-body";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.yaraBrightBlue.main,
    border: `${theme.palette.common.white} solid 2px`,
    color: theme.palette.common.white,
    fontSize: 14
  },
  [`&.${tableCellClasses.body}`]: {
    border: `${theme.palette.common.white} solid 2px`,
    fontSize: 14,
    color: theme.palette.yaraBrightBlue.contrastText
  },
  "&.link:hover": {
    textDecoration: "underline"
  }
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.yaraBrightBlue.light
  },
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.yaraBrightBlue.lighter
  }
}));

const CardContentNoPadding: FC<HTMLAttributes<HTMLElement>> = (p) => (
  <TwCardBody style={{
    ...p.style,
    overflow: 'hidden',
    borderRadius: 'inherit',
    padding: 0
  }}>
    {p.children}
  </TwCardBody>
)

const UserStatisticsWidget: FC<HTMLAttributes<HTMLElement>> = ({className}) => {
  const [t] = useCaseInsensitiveTranslation();
  const isMobile = useIsMobile()
  const today = new Date();
  const oneMonthAgo = new Date(new Date().setDate(today.getDate() - 30));
  function getUtcOffset(date: Date | undefined) {
    if (date !== undefined) return moment(date).utcOffset();
    return 0.0;
  }

  const variables = useMemo(() => {
    return {
      input: {
        fromDate: oneMonthAgo,
        fromDateOffset: getUtcOffset(oneMonthAgo),
        toDate: today,
        toDateOffset: getUtcOffset(today)
      }
    };
  }, []);

  const { data, error, loading, revalidate } = useQuery(
    UserStatisticsDashboardWidgetQueryDocument,
    variables
  );

  const item = data?.reports.userStatistics?.widget;

  return (
    <TwCard className={classNames(className)}>
      {loading && (
        <TwCardBody style={!isMobile ? { height: 250 } : undefined}>
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
        </TwCardBody>
      )}
      {error && !loading && (
        <TwCardBody
          style={!isMobile ? { height: 250 } : undefined}
          className="flex column jccenter aicenter"
        >
          <div>{t("UI_Dashboard_Item_ErrorLoadingData")}</div>
          <Button onClick={revalidate}>{t("UI_Common_TryAgain")}</Button>
        </TwCardBody>
      )}
      {!loading && data && (
        <CardContentNoPadding>
          <Table sx={!isMobile ? { height: 250 } : undefined}>
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <Typography typography="h5">
                    {t("UI_Dashboard_Item_UserStatistics")}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell className="link" align="center">
                  <a
                    className="no-text-decoration flex ic"
                    href="/usermanager?internalUsers=true"
                  >
                    Internal
                  </a>
                </StyledTableCell>
                <StyledTableCell className="link" align="center">
                  <a
                    className="no-text-decoration flex ic"
                    href="/usermanager?externalUsers=true"
                  >
                    External
                  </a>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell className="link">
                  <a
                    className="no-text-decoration flex ic"
                    href="/usermanager?totalUsers=true"
                  >
                    {t("UI_Dashboard_Item_UserStatistics_Total")}
                  </a>
                </StyledTableCell>
                <StyledTableCell className="link" align="center">
                  <a
                    className="no-text-decoration text-align-center ic"
                    href="/usermanager?internalUsers=true"
                  >
                    {item?.totalInternal}
                  </a>
                </StyledTableCell>
                <StyledTableCell className="link" align="center">
                  <a
                    className="no-text-decoration text-align-center ic"
                    href="/usermanager?externalUsers=true"
                  >
                    {item?.totalExternal}
                  </a>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell className="link">
                  <a
                    className="no-text-decoration flex ic"
                    href="/usermanager?activeUsersLast30Days=true"
                  >
                    {t("UI_Dashboard_Item_UserStatistics_Active")}
                  </a>
                </StyledTableCell>
                <StyledTableCell className="link" align="center">
                  <a
                    className="no-text-decoration text-align-center ic"
                    href="/usermanager?activeUsersLast30Days=true&internalUsers=true"
                  >
                    {item?.activeInternal}
                  </a>
                </StyledTableCell>
                <StyledTableCell className="link" align="center">
                  <a
                    className="no-text-decoration text-align-center ic"
                    href="/usermanager?activeUsersLast30Days=true&externalUsers=true"
                  >
                    {item?.activeExternal}
                  </a>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell className="link">
                  <a
                    className="no-text-decoration flex ic"
                    href="/usermanager?deletedUsersLast30Days=true"
                  >
                    {t("UI_Dashboard_Item_UserStatistics_Deleted")}
                  </a>
                </StyledTableCell>
                <StyledTableCell className="link" align="center">
                  <a
                    className="no-text-decoration text-align-center ic"
                    href="/usermanager?deletedUsersLast30Days=true&internalUsers=true"
                  >
                    {item?.deletedInternal}
                  </a>
                </StyledTableCell>
                <StyledTableCell className="link" align="center">
                  <a
                    className="no-text-decoration text-align-center ic"
                    href="/usermanager?deletedUsersLast30Days=true&externalUsers=true"
                  >
                    {item?.deletedExternal}
                  </a>{" "}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell className="link">
                  <a
                    className="no-text-decoration flex ic"
                    href="/usermanager?manageUserAccess=true"
                  >
                    {t("UI_Dashboard_Item_UserStatistics_ManageUsers")}
                  </a>
                </StyledTableCell>
                <StyledTableCell className="link" align="center">
                  <a
                    className="no-text-decoration text-align-center ic"
                    href="/usermanager?manageUserAccess=true&internalUsers=true"
                  >
                    {item?.userManagerAccessInternal}
                  </a>
                </StyledTableCell>
                <StyledTableCell className="link" align="center">
                  <a
                    className="no-text-decoration text-align-center ic"
                    href="/usermanager?manageUserAccess=true&externalUsers=true"
                  >
                    {item?.userManagerAccessExternal}
                  </a>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell className="link">
                  <a
                    className="no-text-decoration text-align-center ic"
                    href="/usermanager?expiresSoon=true"
                  >
                    Expires soon
                  </a>
                </StyledTableCell>
                <StyledTableCell className="link" align="center">
                  <a
                    className="no-text-decoration text-align-center ic"
                    href="/usermanager?expiresSoon=true&internalUsers=true"
                  >
                    {item?.pendingDeletionInternal}
                  </a>
                </StyledTableCell>
                <StyledTableCell className="link" align="center">
                  <a
                    className="no-text-decoration text-align-center ic"
                    href="/usermanager?expiresSoon=true&externalUsers=true"
                  >
                    {item?.pendingDeletionExternal}
                  </a>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </CardContentNoPadding>
      )}
    </TwCard>
  );
};

export default UserStatisticsWidget;
