import { getAllowedColumns } from "$pages/common"
import { DashboardItemNameEnum } from "$pages/dashboard/dashboard-edit/DashboardItemNameEnum"
import useItemAccessRecord from "$pages/dashboard/hooks/useItemAccessRecord"
import usePersistedItemVisibility from "$pages/dashboard/hooks/usePersistedItemVisibility"
import { getSession } from "../../../../config/sessionService"

export type QuickSearchVisibilityRecord = {
  customerRef: boolean,
  shipTo: boolean,
  soldTo: boolean,
  freetext: boolean,
  deliveryNote: boolean,
  channelId: boolean,
}

export function useQuickSearchVisibility(): QuickSearchVisibilityRecord {
  const { items } = usePersistedItemVisibility()
  const access = useItemAccessRecord().QuickSearch
  const allowedColumns = getAllowedColumns(getSession())

  const hasAccess = (a: boolean, item: DashboardItemNameEnum, b?: boolean) => {
    const initialAccess: boolean = a && items.includes(item)
    if (b !== undefined) return initialAccess && b
    return initialAccess
  }

  return {
    customerRef: hasAccess(access.CustomerReference, DashboardItemNameEnum.CustomerRef, allowedColumns.CustomerRef),
    shipTo: hasAccess(access.ShipTo, DashboardItemNameEnum.ShipTo, allowedColumns.ShipTo),
    soldTo: hasAccess(access.SoldTo, DashboardItemNameEnum.SoldTo, allowedColumns.SoldTo),
    freetext: hasAccess(access.Freetext, DashboardItemNameEnum.FreeText),
    deliveryNote: hasAccess(access.DeliveryNote, DashboardItemNameEnum.DeliveryNote),
    channelId: hasAccess(access.ChannelId , DashboardItemNameEnum.ChannelId),
  }
}
