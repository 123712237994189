export function useAuth() {
  /**
   * # Logout
   * Written to target Aurelia Authentication.
   */
  function logout() {
    const TokenKey = 'aurelia_authentication'
    localStorage.removeItem(TokenKey)
  }
  
  return [logout]
}
