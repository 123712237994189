import React, {FC, HTMLAttributes} from "react";
import './tw-card.css'
import classNames from "classnames";

/**
 * # Tailwind Card
 * The intended usage is as follows:
 * ```tsx
 *   <TwCard>
 *     <TwCardHeader />
 *     <TwCardBody />
 *     <TwCardFooter />
 *   </TwCard>
 * ```
 * @param props
 * @constructor
 */
const TwCard: FC<HTMLAttributes<HTMLElement>> = (props) => {
  return (
    <div className={classNames('tw-card bg-white rounded flex flex-col items-stretch', props.className)}>
      { props.children }
    </div>
  )
}

export default TwCard
