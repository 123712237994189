import { DashboardItemNameEnum } from "$pages/dashboard/dashboard-edit/DashboardItemNameEnum";
import useItemAccessRecord from "$pages/dashboard/hooks/useItemAccessRecord";
import usePersistedItemVisibility from "$pages/dashboard/hooks/usePersistedItemVisibility";

export default function usePinnedSitesVisibility() {
  const { items } = usePersistedItemVisibility()
  const accessRecord = useItemAccessRecord().PinnedSites

  const hasAccess = (a: boolean, item: DashboardItemNameEnum, b?: boolean) => {
    const initialAccess: boolean = a && items.includes(item)
    if (b !== undefined) return initialAccess && b
    return initialAccess
  }

  return {
    pinnedSites: hasAccess(accessRecord.PinnedSites, DashboardItemNameEnum.PinnesSites)
  }
}