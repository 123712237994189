import TwCard from "$components/cards/tw-card/tw-card";
import TwCardBody from "$components/cards/tw-card/tw-card-body";
import TwCardFooter from "$components/cards/tw-card/tw-card-footer";
import TwCardHeader from "$components/cards/tw-card/tw-card-header";
import Icon from "$components/icons/icon/icon.react";
import RenderIf from "$components/render-if/render-if";
import { useCaseInsensitiveTranslation } from "$lib/hooks/case-insensitive-translation";
import classNames from "classnames";
import React, { HTMLAttributes } from "react";

export interface StatelessQuickSearchWidgetProps extends HTMLAttributes<HTMLElement> {
  header: string
  searchComponent: JSX.Element
  isLoading: boolean
  showResult: boolean
  showNoMatch: boolean
  searchResultComponent: JSX.Element
  dataCount?: number
  url: string
}

export default function StatelessQuickSearchWidget(props: StatelessQuickSearchWidgetProps) {
  const [t] = useCaseInsensitiveTranslation()
  const showNothing: boolean = !props.showResult && !props.showNoMatch && !props.isLoading
  return (
    <TwCard className={classNames(props.className)}>
      <TwCardHeader>{ props.header }</TwCardHeader>
      <TwCardBody className="flex-col gap-4">
        { props.searchComponent }
        <RenderIf if={props.showResult}>
          { props.searchResultComponent }
        </RenderIf>
        <RenderIf if={props.showNoMatch}>
          <p>{t('UI_Dashboard_Quicksearch_No_Search_Match')}</p>
        </RenderIf>
        <RenderIf if={props.isLoading}>
          <p>{t('UI_Common_Loading')}...</p>
        </RenderIf>
        <RenderIf if={showNothing}>
          <p>&nbsp;</p>
        </RenderIf>
      </TwCardBody>
      <TwCardFooter className="justify-end">
        <RenderIf
          if={props.showResult}
          else={<p>&nbsp;</p>}>
          <a
            href={props.url}
            className="no-underline flex gap-2">
            <p>See all {props.dataCount ?? 0} results</p>
            <Icon name="arrow-right" />
          </a>
        </RenderIf>
      </TwCardFooter>
    </TwCard>
  )
}
