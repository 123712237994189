import React, {FC, HTMLAttributes} from "react";
import classNames from "classnames";

const TwCardFooter: FC<HTMLAttributes<HTMLElement>> = (props) => {
  return (
    <div className={classNames(
      'rounded-b p-4 bg-gray-50 border-t border-solid border-slate-100 flex items-center',
      props.className
    )}>
      { props.children }
    </div>
  )
}

export default TwCardFooter
