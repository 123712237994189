import React, {FC, HTMLAttributes, useEffect, useMemo, useRef, useState} from 'react'
import './widget-scroller.css'
import Icon from "$components/icons/icon/icon.react"
import {usePersistedState} from "$lib/hooks/usePersistedState"
import debounce from "lodash.debounce"
import classNames from "classnames";

export interface WidgetScrollerProps extends HTMLAttributes<HTMLElement> {
  name: string
  rememberSlide: boolean
  onSlideTitle?: (str: string) => void
}

const WidgetScroller: FC<WidgetScrollerProps> = (props) => {
  const CacheKey = props.name + '-slide'
  const DefaultBuid = 'All'
  
  const scrollerRef = useRef<HTMLDivElement>(null)
  const [slideIndex, setSlideIndex] = useState<number>(0)
  const [slideCount, setSlideCount] = useState<number>(0)
  const [cachedTitle, setCachedTitle] = usePersistedState(CacheKey, DefaultBuid)
  
  // Load the cached title
  function loadCachedTitle() {
    const notDefault = (!!cachedTitle) && cachedTitle !== DefaultBuid
    if (notDefault) {
      const index = getSlideIndex(cachedTitle)
      if (index) {
        setSlideIndex(index)
      }
    }
  }
  
  // Invoked when the client changes the slide
  function onSlideChangeByClient(newIndex: number) {
    const title = getSlideTitle(newIndex)
    const diff = title !== cachedTitle
    if (title && diff) {
      setCachedTitle(title)
    }
  }

  // Updates title and index from scroll event
  const updateSlideIndexFromScroll = debounce(
    (index: number) => setSlideIndex(index),
    200
  )
  
  // Update slide count when children are updated
  useEffect(() => {
    const nChildren = scrollerRef.current?.children?.length ?? 0
    setSlideCount(nChildren)
  }, [props.children]);
  
  // Get slide title from index number
  function getSlideTitle(index: number): string | null {
    const children = scrollerRef?.current?.children
    const correspondingChild = children?.item(index)
    const title = (correspondingChild as HTMLElement | null | undefined)?.title
    return title ?? null
  }
  
  // Get slide index from title
  function getSlideIndex(title: string): number | null {
    const children = Array.from(scrollerRef?.current?.children ?? [])
    const titles = (children as HTMLElement[]).map(e => e.title)
    const index = titles.indexOf(title)
    const notFound = index < 0
    return notFound ? null : index
  }
  
  // Update slide title when slide index and -count are updated
  useEffect(
    () => {
      const updatedTitle = getSlideTitle(slideIndex) ?? ''
      setCachedTitle(updatedTitle)
      props.onSlideTitle && props.onSlideTitle(updatedTitle)
    },
    [slideIndex, slideCount]
  )
  
  // Update disabled state based on the current slide index
  const leftArrowDisabled = useMemo(
    () => slideIndex === 0,
    [slideIndex]
  )
  
  // Update disabled state based on the current slide index and -count
  const rightArrowDisabled = useMemo(
    () => slideIndex === (slideCount - 1),
    [slideIndex, slideCount]
  )

  const LeftArrowButton = useMemo(
    () => () => (
      <Icon
        name="fa-caret-left"
        className={classNames(leftArrowDisabled && 'disabled')}
        style={{fontSize: '1.5rem'}}
        onClick={prevSlide} />
    ),
    [slideIndex, leftArrowDisabled]
  )

  const RightArrowButton = useMemo(
    () => () => (
      <Icon
        name="fa-caret-right"
        className={classNames(rightArrowDisabled && 'disabled')}
        style={{fontSize: '1.5rem'}}
        onClick={nextSlide} />
    ),
    [slideIndex, slideCount, rightArrowDisabled]
  )
  
  // Scroll horizontally based on the current slide index
  function scrollToIndex(index: number, smooth: boolean): void {
    const width = scrollerRef.current?.clientWidth ?? 0
    const left = width * index // i.e. x
    const behavior = smooth ? 'smooth' : 'auto'
    scrollerRef.current?.scrollTo({left, behavior})
  }
  
  // Increment slide index
  function nextSlide(): void {
    if (rightArrowDisabled) return
    const newIndex = slideIndex + 1
    setSlideIndex(newIndex)
    onSlideChangeByClient(newIndex)
  }

  // Decrement slide index
  function prevSlide(): void {
    if (leftArrowDisabled) return
    const newIndex = slideIndex - 1
    setSlideIndex(newIndex)
    onSlideChangeByClient(newIndex)
  }

  // Update scrolling when slide index is updated
  useEffect(() => {
    scrollToIndex(slideIndex, true)
  }, [slideIndex]);
  
  function updateTitle(index: number): void {
    const title: string = getSlideTitle(index) ?? ''
    setCachedTitle(title)
  }
  
  function onScroll(event: Event): void {
    const target: HTMLElement = event.target as HTMLElement
    const quotient: number = target.scrollLeft / target.clientWidth
    const index: number = Math.round(quotient)

    updateTitle(index)
    updateSlideIndexFromScroll(index)
  }
  
  // Add scroll event listener on mount
  function onMount(): void {
    scrollerRef.current?.addEventListener('scroll', onScroll)
    loadCachedTitle()
  }

  // Remove scroll event listener on dismount
  function onDismount(): void {
    scrollerRef.current?.removeEventListener('scroll', onScroll)
  }

  useEffect(() => {
    onMount()
    return () => onDismount()
  }, []);
  
  return (
    <div className="widget-scroller">
      <div className="center-content data-navigator">
        <LeftArrowButton />
        <div className="slide-title">
          { cachedTitle }
        </div>
        <RightArrowButton />
      </div>
      <div
        ref={scrollerRef}
        className="scroller-content-wrapper">
        { props.children }
      </div>
    </div>
  )
}

export default WidgetScroller
