import React, {FC, HTMLAttributes, useEffect, useRef} from 'react'
import './new-sites-chart.css'
import {NewSitesData} from "$pages/dashboard/widgets/modules/new-sites-widget/common/new-sites-data";
import {useIsMobile} from "$lib/hooks/isMobile";
import {Bar} from "chartist";
import {useCaseInsensitiveTranslation} from "$lib/hooks/case-insensitive-translation";

export interface NewSitesChartProps extends HTMLAttributes<HTMLElement> {
  data: NewSitesData
}

const NewSitesChart: FC<NewSitesChartProps> = (props) => {
  const [t] = useCaseInsensitiveTranslation()
  const chartDiv = useRef<HTMLDivElement>(null)
  const isMobile = useIsMobile()

  function renderChart (): void {
    if (!props.data || !chartDiv.current) return;
    new Bar(chartDiv.current, {
      labels: props.data.months.map(m => t(m.monthShortText)),
      series: [props.data.months.map(m => m.count)]
    }, {
      axisX: {
        showGrid: false,
        labelOffset: { y: 10 }
      },
      axisY: {
        onlyInteger:true,
        offset: isMobile ? 50 : 40,
        showLabel: !!props.data,
        labelInterpolationFnc: (label: number) => {
          return isMobile ? label : label.toLocaleString();
        }
      }
    });
  }

  // Render chart on mount
  useEffect(() => {
    renderChart()
  }, []);
  
  return <div
    ref={chartDiv}
    className="new-sites-chart"/>
}

export default NewSitesChart
