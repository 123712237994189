import { CustomerRefQuickSearchVariables } from "$typings/graphql";
import { QuickSearchFilterInterface } from "../../interfaces/quick-search-filter-interface";

export default function useCustomerRefQuickSearchVariables(searchValue: string): CustomerRefQuickSearchVariables {
  const filter: QuickSearchFilterInterface = {
    field: 'customerRef',
    type: 'string',
    exclude: false,
    partialMatch: true,
    filters: [{value: searchValue, selected: true }],
  }
  
  return {
    filter: JSON.stringify([filter])
  }
}