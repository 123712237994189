import { useQuery } from "$lib/hooks/fetch-utillities";
import { SoldToQuickSearchDocument } from "$typings/graphql-codegen";
import { useMemo } from "react";
import { ISoldToQuickSearchResult } from "../ISoldToQuickSearchResult";
import useSoldToQuickSearchVariables from "./useSoldToQuickSearchVariables";
import { usePersistedState } from "$lib/hooks/usePersistedState";

export default function useSoldToQuickSearchQuery() {
  const CacheKey: string = 'quickSearchSoldToKey'
  const [searchValue, setSearchValue] = usePersistedState(CacheKey, '')
  const variables = useSoldToQuickSearchVariables(searchValue)
  const {
    data,
    loading
  } = useQuery(
    SoldToQuickSearchDocument,
    variables
  )

  const mutatedData = useMemo<ISoldToQuickSearchResult | undefined>(
    () => {
      const siteListData = data?.elasticSearchPages.sitelist.data
      const items = siteListData?.edges ?? []
      const totalCount = siteListData?.totalCount ?? 0
      const noData = totalCount < 1 || items.length < 1
      if (noData) return undefined

      const firstItem = items[0]
      return {
        customer: firstItem?.customer?.name ?? 'NO_NAME',
        totalCount
      }
    },
    [data, loading]
  )

  const hasSearchValue = useMemo(
    () => !!searchValue && searchValue.length > 0,
    [searchValue]
  )
  
  const hasCustomer = useMemo(() => {
    const siteList = data?.elasticSearchPages.sitelist
    const edges = siteList?.data.edges ?? []
    const [edge] = edges
    return !!edge && !!edge.customer
  }, [data])
  
  const showResult = useMemo(() => {
    return !loading && hasSearchValue && hasCustomer
  }, [hasCustomer, hasSearchValue])
  
  const showNoMatch = useMemo(() => {
    return !loading && hasSearchValue && !hasCustomer
  }, [hasCustomer])

  const siteListUrl = useMemo(
    () => searchValue ? `sitelistpage?soldto=${searchValue}` : '',
    [searchValue]
  )

  return {
    data,
    mutatedData,
    loading,
    showResult,
    showNoMatch,
    siteListUrl,
    searchValue,
    setSearchValue,
  }
}
