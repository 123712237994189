import Icon from "$components/icons/icon/icon.react";
import RenderIf from "$components/render-if/render-if";
import TextField from "$components/textbox/text-field.react";
import { useCaseInsensitiveTranslation } from "$lib/hooks/case-insensitive-translation";
import DashboardSiteChannelBar from "$pages/dashboard/common/dashboard-site-channel-bar";
import React, { FC } from 'react';
import StatelessQuickSearchWidget from '../common/stateless-quick-search-widget';
import useShipToQuickSearchQuery from './hooks/useShipToQuickSearchQuery';

export interface ShipToQuickSearchProps {}

const ShipToQuickSearch: FC<ShipToQuickSearchProps> = () => {
  const [t] = useCaseInsensitiveTranslation()
  const Header = t('UI_Dashboard_Quicksearch_ShipTo')

  const {
    mutatedData,
    loading,
    siteUrl,
    siteListUrl,
    showResult,
    showNoMatch,
    searchValue,
    setSearchValue,
  } = useShipToQuickSearchQuery()
  
  const SearchIcon = <Icon name="fa-search" />

  const SearchComponent = (
    <TextField
      value={searchValue}
      icon={SearchIcon}
      removeWhitespace={true}
      placeholder={t('UI_Dashboard_Quicksearch_ShipTo')}
      onChange={(e) => setSearchValue(e.target.value)} />
  )

  const SearchResultComponent = (
    <a
      href={siteUrl}
      className="no-underline flex gap-2">
      <RenderIf if={typeof mutatedData?.percentage === 'number'}>
        <DashboardSiteChannelBar
          lastSample={mutatedData?.lastSample}
          percentage={mutatedData?.percentage}
          minimum={mutatedData?.minimum}
          maximum={mutatedData?.maximum}
          capacity={mutatedData?.capacity}
          lastSampleTime={mutatedData?.lastSampleTime}
          reachMinimum={mutatedData?.reachMinimum}>
        </DashboardSiteChannelBar>
      </RenderIf>
      <p className="truncate">{mutatedData?.alias}</p>
    </a>
  )
  
  return (
    <StatelessQuickSearchWidget
      header={Header}
      searchComponent={SearchComponent}
      isLoading={loading}
      showResult={showResult}
      showNoMatch={showNoMatch}
      searchResultComponent={SearchResultComponent}
      dataCount={mutatedData?.totalCount ?? 0}
      url={siteListUrl} />
  )
}

export default ShipToQuickSearch
