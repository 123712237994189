export const SelectedBuidCacheKey = 'ControllerDataSourcesWidgetSelectedBuidKey'
export const PercentOrNumberCacheKey = 'ControllerDataSourcesWidgetPercentKey'
export const NumberOfTypesToShow = 4
export enum Colors {
  RED = '#C2404090',
  GREEN = '#78A22F90',
  BLUE = '#0076BC90',
  GRAY = '#CDCDCD90'
}
export const MainColors = [
  Colors.GREEN,
  Colors.RED,
  Colors.BLUE
]
export type NamedObject = {
  name?: string
}
export function localCompareNamedObjects(a: NamedObject, b: NamedObject): number {
  const nameA = (a.name ?? '') as string
  const nameB = (b.name ?? '') as string
  return nameA.localeCompare(nameB)
}
export function percentReducer(sum: number, next: {percent: number}): number {
  return sum + next.percent
}
export function countReducer(sum: number, next: {count: number}): number {
  return sum + next.count
}
