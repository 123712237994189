import { DashboardItemNameEnum } from '$pages/dashboard/dashboard-edit/DashboardItemNameEnum';
import { IDashboardGroup } from '$pages/dashboard/dashboard-edit/IDashboardGroup';
import useItemAccessRecord from '$pages/dashboard/hooks/useItemAccessRecord';

type AccessMap = Map<DashboardItemNameEnum, boolean>

function createGroup(name: DashboardItemNameEnum, map?: AccessMap): IDashboardGroup {
  const items: DashboardItemNameEnum[] = []
  const addIfAccess = (access: boolean, item: DashboardItemNameEnum): void => {
    access && items.push(item)
  }
  map?.forEach(addIfAccess)
  const hasItems: boolean = items.length > 0
  return {
    name,
    items: hasItems ? items : undefined
  }
}

export function useDashboardGroups(): IDashboardGroup[] {
  const record = useItemAccessRecord()

  const ShortcutAccessMap: AccessMap = new Map(
    [
      [DashboardItemNameEnum.Sitelist, record.Shortcuts.SiteList],
      [DashboardItemNameEnum.SitelistMap, record.Shortcuts.SiteListMap],
      [DashboardItemNameEnum.SitelistOnlineSites, record.Shortcuts.SiteListOnline],
      [DashboardItemNameEnum.SitelistOfflineSites, record.Shortcuts.SiteListOffline],
      [DashboardItemNameEnum.SitelistParkedSites, record.Shortcuts.SiteListParked],
      [DashboardItemNameEnum.SitelistVirtualSites, record.Shortcuts.SiteListVirtual],
      [DashboardItemNameEnum.SitelistActiveAlarms, record.Shortcuts.SiteListActiveAlarms],
      [DashboardItemNameEnum.Releasenotes, record.Shortcuts.ReleaseNotes],
      [DashboardItemNameEnum.LiveMap, record.Shortcuts.LiveMap]
    ]
  )

  const QuickSearchAccessMap: AccessMap = new Map(
    [
      [DashboardItemNameEnum.CustomerRef, record.QuickSearch.CustomerReference],
      [DashboardItemNameEnum.ShipTo, record.QuickSearch.ShipTo],
      [DashboardItemNameEnum.SoldTo, record.QuickSearch.SoldTo],
      [DashboardItemNameEnum.FreeText, record.QuickSearch.Freetext],
      [DashboardItemNameEnum.DeliveryNote, record.QuickSearch.DeliveryNote],
      [DashboardItemNameEnum.ChannelId, record.QuickSearch.ChannelId],
    ]
  )

  const WidgetAccessMap: AccessMap = new Map(
    [
      [DashboardItemNameEnum.LowestLevels, record.Widgets.LowestLevels],
      [DashboardItemNameEnum.NextReachMinimum, record.Widgets.NextReachMinimum],
      [DashboardItemNameEnum.RecentlyViewedSites, record.Widgets.RecentlyViewedSites],
      [DashboardItemNameEnum.DeliveriesLast, record.Widgets.LastDeliveries],
      [DashboardItemNameEnum.DeliveriesYTD, record.Widgets.DeliveriesYTD],
      [DashboardItemNameEnum.DataSources, record.Widgets.DataSources],
      [DashboardItemNameEnum.UserStatistics, record.Widgets.UserStatistics],
      [DashboardItemNameEnum.Controllers, record.Widgets.Controllers],
      [DashboardItemNameEnum.NewSites, record.Widgets.NewSites],
      [DashboardItemNameEnum.Lifecycle, record.Widgets.Lifecycle]
    ]
  )
  
  function someAreTrue(map: AccessMap): boolean {
    return Array.from(map.values()).some(v => v)
  }
  
  const Groups = []
  
  if (someAreTrue(ShortcutAccessMap)) {
    const Shortcuts: IDashboardGroup = createGroup(
      DashboardItemNameEnum.Shortcuts,
      ShortcutAccessMap
    )
    Groups.push(Shortcuts)
  }

  if (someAreTrue(QuickSearchAccessMap)) {
    const QuickSearch: IDashboardGroup = createGroup(
      DashboardItemNameEnum.QuickSearch,
      QuickSearchAccessMap
    )

    Groups.push(QuickSearch)
  }

  if (someAreTrue(WidgetAccessMap)) {
    const Widgets: IDashboardGroup = createGroup(
      DashboardItemNameEnum.Widgets,
      WidgetAccessMap
    )
    Groups.push(Widgets)
  }

  if (record.PinnedSites.PinnedSites) {
    const PinnedSites: IDashboardGroup = createGroup(
      DashboardItemNameEnum.PinnesSites,
      undefined
    )
    Groups.push(PinnedSites)
  }

  return Groups
}
