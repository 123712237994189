import React, {FC, HTMLAttributes, useMemo} from 'react'
import './controllers-data-sources-widget.css'
import {useCaseInsensitiveTranslation} from "$lib/hooks/case-insensitive-translation";
import Icon from "$components/icons/icon/icon.react";
import {useQuery} from "$lib/hooks/fetch-utillities";
import {DataSourcesWidgetDataDocument} from "$typings/graphql-codegen";
import {distinct, groupBy, removeNoneFromArray} from "$lib/arrayHelpers";
import {orderByPredicate} from "$lib/sorting";
import RenderIf from "$components/render-if/render-if";
import PlaceholderText from "$components/placeholders/placeholder-text/placeholder-text.react";
import {CustomIcon} from "$components/icons/icon/CustomIcon";
import {getUserFeatures} from "../../../../../config/sessionService";
import {useIsMobile} from "$lib/hooks/isMobile";
import WidgetScroller from "$pages/dashboard/widgets/modules/scroller/widget-scroller";
import {usePersistedBooleanState, usePersistedState} from "$lib/hooks/usePersistedState";
import {
  Colors,
  countReducer,
  localCompareNamedObjects,
  MainColors,
  NumberOfTypesToShow,
  PercentOrNumberCacheKey,
  percentReducer,
  SelectedBuidCacheKey
} from "$pages/dashboard/widgets/modules/controllers-data-sources-widget/controllers-data-sources";
import {round} from "$lib/helpers";
import TwCard from "$components/cards/tw-card/tw-card";
import TwCardHeader from "$components/cards/tw-card/tw-card-header";
import TwCardBody from "$components/cards/tw-card/tw-card-body";
import TwCardFooter from "$components/cards/tw-card/tw-card-footer";
import classNames from "classnames";

export interface ControllersDataSourcesWidgetProps extends HTMLAttributes<HTMLElement> {}

const ControllersDataSourcesWidget: FC<ControllersDataSourcesWidgetProps> = (props) => {
  const [t] = useCaseInsensitiveTranslation()
  const [selectedBuid, setSelectedBuid] = usePersistedState(SelectedBuidCacheKey, '')
  const [showPercent, setShowPercent] = usePersistedBooleanState(PercentOrNumberCacheKey, true)
  
  const features = getUserFeatures()
  const isMobile = useIsMobile()
  
  const {
    data,
    loading
  } = useQuery(
    DataSourcesWidgetDataDocument
  )
  
  const buidObjects = useMemo(
    () => data?.dataSourcesWidgetData.map(v => v.buid) ?? [],
    [data]
  )
  
  const distinctBuids = useMemo(
    () => {
      let buids = distinct(buidObjects, (o) => o?.buidId)
      buids = removeNoneFromArray(buids)
      buids.sort(localCompareNamedObjects)
      const item = {
        name: 'All',
        buidId: null
      }
      buids.unshift(item)
      return buids
    },
    [data]
  )
  
  const controllerManagerURL = useMemo<string>(
    () => {
      const whereNameMatchSelectedBuid = (o: {name?: string}) => o.name === selectedBuid
      const foundBuidObject = distinctBuids.find(whereNameMatchSelectedBuid)
      const buidId = foundBuidObject?.buidId ?? ''
      const baseUrl = '/controllermanager?assigned=true'
      return buidId ? `${baseUrl}&buid=${buidId}` : baseUrl
    },
    [selectedBuid]
  )
  
  function getStrokeOffset(index: number, array: any[]): number {
    const slicedCopy = array.slice(0, index)
    const percentageSum = slicedCopy.reduce(percentReducer, 0)
    return 100 - percentageSum + 25
  }
  
  function onSlideTitle(buid: string): void {
    setSelectedBuid(buid)
  }
  
  function toggleShowPercent(): void {
    setShowPercent(!showPercent)
  }
  
  function getSortedData(buid?: string) {
    const noData = !data
    if (noData) return []

    let controllerData = data?.dataSourcesWidgetData ?? []
    const isAll = buid === 'All'

    // Filter if necessary
    if (!isAll) {
      controllerData = controllerData.filter((obj) => obj?.buid?.name === buid)
    }

    const controllerTypeSelector = (c: any) => c.controllerType
    const groupedByControllerType = groupBy(controllerData, controllerTypeSelector)
    
    const allControllers = Object.values(groupedByControllerType).map((o) => {
      const count = o.reduce(countReducer, 0)
      const controllerType = o[0].controllerType
      const buid = {
        name: 'All',
        buidId: ''
      }
      return {
        count,
        controllerType,
        buid
      }
    })
    
    type Controller = {count: number, controllerType: string}
    const controllers  = (isAll ? allControllers : controllerData) as Controller[]
    const noControllers = (controllers?.length ?? 0) < 0
    if (noControllers) return []

    const total = controllers.reduce(countReducer, 0)
    const sortedControllers = orderByPredicate(
      controllers,
      c => c.count,
      'desc'
    )

    const pivot = NumberOfTypesToShow - 1
    const visibleRange = [0, pivot]
    const overflowRange = [pivot]
    const visibleControllers = sortedControllers.slice(...visibleRange)
    const overflowControllers = sortedControllers.slice(...overflowRange)
    
    const overflowControllersCountSum: number = overflowControllers.reduce(countReducer, 0)
    
    const result = visibleControllers.map((o, i) => ({
      color: MainColors[i],
      type: o.controllerType,
      percent: (o.count / total) * 100,
      count: o.count
    }))
    
    if (overflowControllersCountSum) {
      const sumPercent = result.reduce(percentReducer, 0)
      result.push({
        color: Colors.GRAY,
        type: 'Other',
        percent: 100 - sumPercent,
        count: overflowControllersCountSum
      })
    }
    
    return result
  }
  
  const PercentNumIcon = () => {
    return <Icon
      name={showPercent ? 'fa-hashtag' : 'fa-percent'}
      onClick={toggleShowPercent} />
  }

  const Circle = (
    props: {
      item: { percent: number, color: string },
      buid: string,
      idx: number
    }
  ) => {
    const percent = props.item.percent
    return (
      <circle
        className="donut-slice"
        strokeDasharray={`${percent} ${100 - percent}`}
        strokeDashoffset={getStrokeOffset(props.idx, getSortedData(props.buid))}
        stroke={props.item.color}
        cx="20"
        cy="20"
        r="16" />
    )
  }
  
  const ControllerHyperLink = (props: {buidId?: string | null, type?: string | null}) => {
    const base = '/controllermanager'
    const controllerType = `?controllerType=${props?.type}&assigned=true`
    const buid = props?.buidId ? `&buid=${props.buidId}` : ''
    const href = base + controllerType + buid
    return <a className="no-underline" href={href}>{ props?.type }</a>
  }

  return (
    <TwCard className={classNames('controllers-data-sources-widget', props.className)}>
      <TwCardHeader className="justify-between">
        <div>{t('UI_Widget_Header_Data_Sources')}</div>
        <PercentNumIcon />
      </TwCardHeader>
      <TwCardBody className="flex-col gap-4 cdsw-body">
        <RenderIf if={data}>
          <WidgetScroller
            rememberSlide={true}
            className="h-full"
            name={'controllers-data-sources-widget'}
            onSlideTitle={onSlideTitle}>
            {distinctBuids.map((buid, i) => (
              <div
                key={i}
                title={buid?.name ?? ''}
                className="flex flex_1 datawrapper">
                <svg
                  className="chart"
                  viewBox="0 0 40 40">
                  {getSortedData(buid?.name ?? '').map((item, j) => (
                    <Circle
                      key={j}
                      item={item}
                      buid={buid?.name ?? ''}
                      idx={j} />
                  ))}
                </svg>
                <div className="typewrapper flex column jcaround">
                  {getSortedData(buid?.name ?? '').map((data, j) => (
                    <div key={j}>
                      <RenderIf if={!showPercent || data.percent}>
                        <div className="flex jsb row">
                          <div
                            className="circle"
                            style={{
                              background: data.color,
                              border: `1px solid ${data.color}`
                            }} />
                          <div className="sourcetype">
                            <RenderIf if={data.type !== 'Other' && features.controllerManagement.read}>
                              <ControllerHyperLink
                                buidId={buid?.buidId}
                                type={data.type} />
                            </RenderIf>
                            <RenderIf if={data.type === 'Other' || !features.controllerManagement.read}>
                              <p>{ data.type }</p>
                            </RenderIf>
                          </div>
                          <RenderIf if={showPercent && data.percent}>
                            <div className="percentage">
                              { round(data.percent) } %
                            </div>
                          </RenderIf>
                          <RenderIf if={!showPercent && data.count}>
                            <div className="percentage">
                              { data.count }
                            </div>
                          </RenderIf>
                        </div>
                      </RenderIf>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </WidgetScroller>
        </RenderIf>
        <RenderIf if={loading}>
          <div className="flex flex_1 datawrapper">
            <div className="chart">
            </div>
            <div className="typewrapper flex column jcaround">
              {(Array(NumberOfTypesToShow).fill(null).map((_, i) => (
                <div
                  key={i}
                  className="flex jsb row">
                  <div
                    className="circle"
                    style={{
                      background: '#efefef',
                      border: '1px solid #efefef'
                    }} />
                  <div className="sourcetype">
                    <PlaceholderText/>
                  </div>
                  <div className="percentage">
                    <PlaceholderText/>
                  </div>
                </div>
              )))}
            </div>
          </div>
        </RenderIf>
      </TwCardBody>
      <RenderIf if={features.controllerManagement.read && !isMobile && (loading || data)}>
        <TwCardFooter className="justify-end">
          <RenderIf if={loading}>
            <PlaceholderText/>
          </RenderIf>
          <RenderIf if={data}>
            <a
              className="no-underline flex gap-2"
              href={controllerManagerURL}>
              Open in controller manager
              <Icon name={CustomIcon.ARROW_RIGHT} />
            </a>
          </RenderIf>
        </TwCardFooter>
      </RenderIf>
    </TwCard>
  )
}

export default ControllersDataSourcesWidget
