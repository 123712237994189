import React, {FC, HTMLAttributes, useMemo} from 'react'
import './sitecard.css'
import {useCaseInsensitiveTranslation} from "$lib/hooks/case-insensitive-translation";
import {ISiteCardSite} from "$interfaces/application";
import {ISiteListSite} from "$interfaces/entity";
import {
  SignalEnum,
  SiteCardFragment,
  SiteCardFragment_alarms,
  SiteCardFragment_controllers,
  SiteStatusBarFragment
} from "$typings/graphql";
import {isEmpty} from "lodash";
import {distinct, removeNoneFromArray} from "$lib/arrayHelpers";
import RenderIf from "$components/render-if/render-if";
import Icon from "$components/icons/icon/icon.react";
import {CustomIcon} from "$components/icons/icon/CustomIcon";
import SiteChannelBar from "$components/bars/site-channel-bar/site-channel-bar.react";
import SiteSignalComponent from "$components/site-signal/site-signal.react";
import SiteStatusBar from "$components/bars/site-status-bar-react/site-status-bar";
import {round} from "$lib/helpers";
import classNames from "classnames";

export interface SiteCardProps extends HTMLAttributes<HTMLElement> {
  site: ISiteCardSite | ISiteListSite | SiteCardFragment,
  fromGraphQl: boolean
}

const SiteCard: FC<SiteCardProps> = (props) => {
  const [t] = useCaseInsensitiveTranslation()
  
  const showToggleTanks: boolean = useMemo(
    () => {
      const tanks = props?.site?.tanks ?? []
      return tanks.length > 1
    },
    [props.site]
  )
  
  function hasActiveAlarms(site: SiteCardFragment): boolean {
    const hasAlarms = !isEmpty(site.alarms)
    const isActive = (alarm: SiteCardFragment_alarms) => alarm.active
    const allAlarmsActive = site.alarms.every(isActive)
    return hasAlarms && allAlarmsActive
  }
  
  function isVirtual(site: SiteCardFragment): boolean {
    const hasControllers = !isEmpty(site.controllers)
    const isVirtual = (controller: SiteCardFragment_controllers) => controller.isVirual
    const allControllersVirtual = site.controllers.every(isVirtual)
    return hasControllers && allControllersVirtual
  }
  
  const shipTo = useMemo(
    () => {
      const whereNotEmpty = (value: any) => !isEmpty(value)
      const shipTos = props.site.tanks.map(
        (tank) => tank?.tankDetails?.shipTo
      ).filter(whereNotEmpty)
      const uniqueTanks = distinct(shipTos)
      return removeNoneFromArray(uniqueTanks)
    },
    [props.site]
  )
  
  const displayShipToData: boolean = useMemo(
    () => shipTo.length > 0,
    [shipTo]
  )
  
  const cardClassName: string = useMemo(
    () => {
      const site: SiteCardFragment = props.site as SiteCardFragment
      return classNames(
        'sitecard',
        isVirtual(site) ? 'virtual ' : '',
        hasActiveAlarms(site) ? 'alarm ' : '',
        (site.signal === SignalEnum.Offline) ? 'offline ' : ''
      )
    },
    [props.site]
  )
  
  const siteDetailsUrl: string = useMemo(
    () => {
      const base = '/sitedetails/'
      const id = props.site.siteId
      return base + id
    },
    [props.site]
  )
  
  // Component: Header
  const Header: FC<HTMLAttributes<HTMLElement>> = ({className}) => (
    <div className={className}>
      <div
        className="truncate"
        style={{fontSize: '1.3rem'}}>
        { props.site.alias }
      </div>
      <div
        className="site-signal"
        style={{width: '30px', height: '30px'}}>
        <SiteSignalComponent
          siteSignal={props.site.signal as SignalEnum}
          isVirtual={isVirtual(props.site as SiteCardFragment)} />
      </div>
    </div>
  )
  
  // Component: Shipping data
  const ShipToData: FC<HTMLAttributes<HTMLElement>> = ({className}) => (
    <div className={className}>
      <RenderIf if={!displayShipToData}>
        <span>&nbsp;</span>
      </RenderIf>
      <RenderIf if={displayShipToData}>
        {shipTo.map((ship, i) => (
          <span
            key={i}
            className="csv">
            {ship}
          </span>
        ))}
      </RenderIf>
    </div>
  )

  // Component: Toggle
  const ToggleTanksData: FC<HTMLAttributes<HTMLElement>> = ({className}) => (
    <div className={className}>
      <RenderIf if={showToggleTanks}>
        <div className="flex items-center justify-between">
          <span>{ props.site.tanks.length ?? 0 } tanks</span>
          <Icon
            className="toggle-tank-data-icon"
            name={CustomIcon.CHEVRON_DOWN} />
        </div>
      </RenderIf>
    </div>
  )
  
  // Component: Tank Data
  const TankData: FC<HTMLAttributes<HTMLElement>> = ({className}) => {
    const nTanks = props?.site?.tanks?.length ?? 0
    const noTanks = nTanks < 1
    const multipleTanks = nTanks > 1
    if (noTanks || multipleTanks) return <></> // Nothing to show
    
    // Acquire first tank in array
    const tank = props.site.tanks.at(0)
    
    return (
      <div className={classNames(className)}>
        <div className="flex flex-row items-center flex-nowrap gap-2">
          <div style={{width: '33.33%', height: '15px'}}>
            <SiteChannelBar
              className="h-full"
              minimum={tank?.minimum}
              maximum={tank?.maximum}
              lastSample={tank?.lastSample}
              lastSampleTime={tank?.lastSampleTime}
              reachMinimum={tank?.tankDetails?.reachMinimum}
              percentage={tank?.tankDetails?.percentage}
              capacity={tank?.capacity} />
          </div>
          <RenderIf if={!!tank?.lastSample}>
            <div>
              { tank?.lastSample && round(tank.lastSample, 2) }
            </div>
          </RenderIf>
          <RenderIf if={!!tank?.lastSampleTime}>
            <div className="grow-0">
              { tank?.unit?.symbol }
            </div>
          </RenderIf>
          <RenderIf if={!!tank?.product}>
            <div className="grow-0">
              { t('' + tank?.product?.name) }
            </div>
          </RenderIf>
        </div>
      </div>
    )
  }

  // Component: Content Div
  const ContentDiv: FC<HTMLAttributes<HTMLElement>> = ({className}) => (
    <div className={className}>
      <Header className="site-card-header"/>
      <ShipToData className="site-card-ship-to"/>
      <ToggleTanksData className="site-card-toggle-tank-data"/>
      <TankData className="site-card-tank-data"/>
    </div>
  )
  
 // Component: Status Div
  const StatusDiv: FC<HTMLAttributes<HTMLElement>> = ({className, style}) => (
    <div
      className={classNames(cardClassName, className)}
      style={style}>
      <RenderIf if={props.fromGraphQl}>
        <SiteStatusBar
          siteGraphQl={props.site as SiteStatusBarFragment | undefined}
          onClick={() => void 0}
          noBackground={false} />
      </RenderIf>
      <RenderIf if={!props.fromGraphQl}>
        <SiteStatusBar
          site={props.site as ISiteCardSite | ISiteListSite | undefined}
          onClick={() => void 0}
          noBackground={false} />
      </RenderIf>
    </div>
  )

  return (
    <div
      style={props.style}
      className={classNames(
        'site-card-wrapper',
        'relative',
        'overflow-hidden',
        'card-like',
        'bg-white',
        props.className
      )}>
      <a
        href={siteDetailsUrl}
        style={props.style}
        className="site-card-clickable-wrapper relative flex h-full">
        <StatusDiv className="site-card-status relative inline-flex site-status-bar" />
        <ContentDiv className="site-card-content relative flex flex-col p-3 gap-2" />
      </a>
    </div>
  )
}

export default SiteCard
