import React, {CSSProperties, FC} from "react";
import SiteChannelBar, {ISiteChannelBarProps} from "$components/bars/site-channel-bar/site-channel-bar.react";

export interface DashboardSiteChannelBarProps extends ISiteChannelBarProps {}

// Slightly altered SiteChannelBar for the Dashboard
const DashboardSiteChannelBar: FC<DashboardSiteChannelBarProps> = (props) => {
  const Width: string = '120px'

  const StyleObject: CSSProperties = {
    width: Width,
    minWidth: Width,
    maxWidth: Width,
  }

  return (
    <SiteChannelBar
      style={StyleObject}
      lastSample={props?.lastSample}
      percentage={props?.percentage}
      minimum={props?.minimum}
      maximum={props?.maximum}
      capacity={props?.capacity}
      lastSampleTime={props?.lastSampleTime}
      reachMinimum={props?.reachMinimum}>
    </SiteChannelBar>
  )
}

export default DashboardSiteChannelBar
