import { IDashboardGroup } from '$pages/dashboard/dashboard-edit/IDashboardGroup';
import { createContext, useContext } from 'react';

export interface IDashboardEditContext {
  title: string
  open: {
    isOpen: boolean,
    setOpen: (v: boolean) => void
  }
  groups: IDashboardGroup[]
}

export const DashboardEditContext = createContext<IDashboardEditContext | undefined>(undefined)

export function useDashboardEditContext(): IDashboardEditContext {
  const ctx = useContext(DashboardEditContext)

  if (ctx === undefined) {
    throw new Error('Context DashboardEditContext is undefined. This context must be used with a context provider.')
  }

  return ctx as IDashboardEditContext
}
